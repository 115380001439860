import React, { useState, useEffect, useRef  } from 'react';
import { Editor, EditorContent, useEditor, BubbleMenu } from '@tiptap/react';
import { Mark } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Document from '@tiptap/extension-document'
import TextAlign from '@tiptap/extension-text-align';
import Text from '@tiptap/extension-text';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Placeholder from '@tiptap/extension-placeholder';
import { v4 as uuid } from 'uuid';


import { Collaboration } from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'


import * as Y from 'yjs';
import { WebrtcProvider } from 'y-webrtc';
import { WebsocketProvider } from 'y-websocket';
// Complete SortableJS (with all plugins)
//import Sortable from 'sortablejs/modular/sortable.complete.esm.js'; //I've got this loading LATER, only when the view to this area is toggled with: layoutModeToggle

// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm"; // Component we will create next






import { isVisible } from '@testing-library/user-event/dist/utils';
import { EditorState } from '@tiptap/pm/state';






// Initialize the WebRTC provider
//const provider = new WebrtcProvider('your-room-name4', ydoc);


const thewritingsIdentifier = localStorage.getItem('writingsIdentifier');

 




const StronglyMark = Mark.create({
  name: 'strongly',

  parseHTML() {
    return [
      { tag: 'strongly' }, // Ensuring <strongly> tags are recognized
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['strongly', HTMLAttributes, 0]; // Rendering <strongly> tags around content
  },
});





const EditorComponent = ({ onWorksIdentifierUpdate }) => {
  const [highlightedText, setHighlightedText] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [lastHighlightedText, setLastHighlightedText] = useState('');
  const guidingForceRef = useRef();
  const [guidingForceContent, setGuidingForceContent] = useState('');
  const [setChapter, setSetChapter] = useState('');

  const [WritingsIdentifier, setWritingsIdentifier] = useState('');
  
  const [localSelectedChapter, setLocalSelectedChapter] = useState('');

  const [messages, setMessages] = useState([]);
  const [ResponseMessages, setResponseMessages] = useState([]);

  const [aiNote, setAiNote] = useState('');
  const [noteIdentifier, setNoteIdentifier] = useState('');
  const [notes, setNotes] = useState([]);

  const [sentMessagewithHighlightParts, setSentMessagewithHighlightParts] = useState('');

  const editorRef = useRef(null);

  const guidingForceInput = useRef(null);

  const [selectedNoteIndex, setSelectedNoteIndex] = useState(0);
  const [updatePending, setUpdatePending] = useState(false);

  const [layoutMode, setLayoutMode] = useState(true);
  const [beatlayoutMode, setBeatLayoutMode] = useState();

  // const [chapterDetailsArray, setChapterDetailsArray] = useState([]);
  const [chapterDetailsArray, setChapterDetailsArray] = useState([
    {
      chapterName: 'My Chapter One',
      WritingsIdentifier: '1111111',
      chapterSummary: 'This is a sample chapter to inspire your writing journey.',
      beatsGroup: 'Introduction',
      chapterContent: '<h1>My Chapter One...</h1><p>It was the best of times, it was the worst of times, it was the age of wisdom, it was the age of foolishness, it was the epoch of belief, it was the epoch of incredulity, it was the season of Light, it was the season of Darkness, it was the spring of hope, it was the winter of despair, we had everything before us, we had nothing before us, we were all going direct to Heaven, we were all going direct the other way...<br><br><strong>This EXAMPLE above</strong>, is the start of one of the most iconic writings ever penned. <br>With your guided pen, what will you write?<br><br>Your story starts here...</p>'
    }
    // Add other chapters here as needed
  ]);


  const [orderingStr, setorderingStr] = useState();
 
  const [socket, setSocket] = useState(null);




  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  // const [chapters, setChapters] = useState([]);
  const [chapters, setChapters] = useState([
    {
      chapterName: 'My Chapter One',
      WritingsIdentifier: '1111111',
      chapterSummary: 'This is a sample chapter to inspire your writing journey.',
      beatsGroup: 'Introduction',
      chapterContent: '<h1>My Chapter One...</h1><p>It was the best of times, it was the worst of times, it was the age of wisdom, it was the age of foolishness, it was the epoch of belief, it was the epoch of incredulity, it was the season of Light, it was the season of Darkness, it was the spring of hope, it was the winter of despair, we had everything before us, we had nothing before us, we were all going direct to Heaven, we were all going direct the other way...<br><br><strong>This EXAMPLE above</strong>, is the start of one of the most iconic writings ever penned. <br>With your guided pen, what will you write?<br><br>Your story starts here...</p>'
    }
    // Add other chapters here as needed
  ]);




  const [works, setWorks] = useState('');
  const [worksIdentifier, setWorksIdentifier] = useState('');
  const [writingsID, setWritingsID] = useState('');
  const [chapterTheName, setChapterTheName] = useState('');
  const [saveWritingsIdentifier, setsaveWritingsIdentifier] = useState('');
  const [newChapterInput, setNewChapterInput] = useState('');
  const [chapterContent, setchapterContent] = useState('');
  const [isLoggedIn, setisLoggedIn] = useState('');
  const [selectedWritingsID, setSelectedWritingsID] = useState('');
  
  const [beatorbeatoverviewmode, setbeatorbeatoverviewmode] = useState();

  const [globalBeatSheetSelected, setGlobalBeatSheetSelected] = useState();

  const [selectedChapterIDtoggle, setSelectedChapterIDtoggle] = useState(null);

  const [usage, setUsage] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [isModalProfileOpen, setIsModalProfileOpen] = useState(false);

  const [WorksTitle, setWorksTitle] = useState(null);

  const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') || 'normal');

  const [globalButtonsOrdering, setGlobalButtonsOrdering] = useState([]);

  const [isModalButtonOpen, setIsModalButtonOpen] = useState(false);

  const [atChapterWasUsed, setAtChapterWasUsed] = useState(false);

  //For saving the info of the auto comepleting chapter selection when @ sign is used and chosen from dropdown
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  // Initialize as an array to hold multiple selections
const [selectedSuggestions, setSelectedSuggestions] = useState([]);

// Function to handle adding a new selection (e.g., when a chapter is selected from the dropdown)
const addSelectedSuggestion = (suggestion) => {
  setSelectedSuggestions(prev => [...prev, suggestion]);
};

  // State to hold whether the window is less than 970px wide
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 970);
  // State to hold whether the guidingforce content should be hidden
  const [hideGuidingForce, setHideGuidingForce] = useState(false);


  const [SidebarToggle, setSidebarToggle] = useState(false);
  
  

// Load your Stripe publishable key



const [isMenuVisible, setIsMenuVisible] = useState(false);

const [isExportModalVisible, setIsExportModalVisible] = useState(false);

const [selectedModel, setSelectedModel] = useState('2'); // Default to model 1

const [stripePromise, setStripePromise] = useState(null); //only sets once modal is opened
const [Elements, setElements] = useState(null); //only sets once modal is opened

const [fullscreenMode, setFullScreenMode] = useState(localStorage.getItem('FullScreen') === 'true');

const [isSortableLoaded, setIsSortableLoaded] = useState(false);
const sortableRef = useRef(null); // Ref to store the Sortable module

const [chapterId, setChapterId] = useState(null); //This for altering the Yjs room
const [documentData, setDocumentData] = useState(null);

const [audioUrl, setAudioUrl] = useState('');
const audioRef = useRef(null);

const [isSharedModalOpen, setSharedModalOpen] = useState(false);
const [emails, setEmails] = useState([]);
const [accessLevels, setAccessLevels] = useState({});
const emailInputRef = useRef(null);
const [isProcessing, setIsProcessing] = useState(false);
const [showInviteModal, setShowInviteModal] = useState(false);
const [inviteDetails, setInviteDetails] = useState({ invite: '', email: '' });

const [memberLevel, setMemberLevel] = useState('');
const [ownerEmail, setOwnerEmail] = useState(false);
const [accessType, setAccessType] = useState('');
const [userIsOwner, setUserIsOwner] = useState(false);
const [authorOutreachData, setAuthorOutreachData] = useState('');
const [chapterContentToSet, setChapterContentToSet] = useState('');
const [isModalOpen, setIsModalOpen] = useState(false);

  // Add this state
  const [activeBeat, setActiveBeat] = useState(null);
  const [defaultBeatStyle] = useState('Save the Cat');

  // Add this handler
  const handleBeatSelect = (beatName) => {
    setActiveBeat(beatName);
  };
  




useEffect(() => {
  const ws = new WebSocket('wss://guidedpen.com/API-GuidedPen-CHAT');
  
  const handleMessage = (event) => {
    let content = event.data;

    try {
      const message = JSON.parse(content);
      switch (message.type) {
        case "usageUpdate":
          console.log('Received usage update:', message.data);
          // Set usage with the correct property from the message data
          setUsage(message.data.currentOutputTokens);
          document.getElementById('guidingforce').innerHTML += '<br><br><i style="color: grey;">User:&nbsp;</i> <span>';
          moveCursorToEnd();
          break;
        case "chunk":
          updateGuidingForceHTML(message.data.replace(/\n/g, '<br>'));
          break;
        case "anthropicEnd":
          // Handle end of processing if needed
          break;
        default:
          console.log('Unhandled message type:', message.type);
      }
    } catch (error) {
      console.error('Error processing message:', error);
      // If parsing fails, handle as a chunk of text
      processChunk(content);
    }
  };

  ws.addEventListener('message', handleMessage);
  setSocket(ws); // If you need to use the WebSocket outside this effect

  // Directly introduce the introductory messages with a delay
  introduceMessages();

  return () => {
    ws.removeEventListener('message', handleMessage);
    ws.close();
  };
}, []); 

// Define the function responsible for introducing messages
function introduceMessages() {
  setTimeout(() => {
    document.getElementById('guidingforce').innerHTML = '';
    setTimeout(() => {
      document.getElementById('guidingforce').innerHTML = "I'm GuidedPen,";
            setTimeout(() => {
              document.getElementById('guidingforce').innerHTML = "I'm GuidedPen, here to help you write your next masterpiece...";
                      setTimeout(() => {
                        document.getElementById('guidingforce').innerHTML = "I'm GuidedPen, here to help you write your next masterpiece... <br><br>Highlight some text, then use the arrows, buttons, OR the @ sign!";
                                setTimeout(() => {
                                  document.getElementById('guidingforce').innerHTML = "I'm GuidedPen, here to help you write your next masterpiece... <br><br>Highlight some text, then use the arrows, buttons, OR the @ sign!<br>It's your writing, but <b>together</b> we'll make it shine!<br><br><i style='color: grey;'>User:&nbsp;</i>";
                                }, 400); // Add the final part of the message after a 0.4-second delay
                      }, 500); // Add the third part of the message after a 0.5-second delay
            }, 500); // Add the second part of the message after a 0.5-second delay
    }, 800); // Start the introduction after a 0.8-second delay
  }, 400); // Initial delay before starting the introduction
}





function updateGuidingForceHTML(content) {
  document.getElementById('guidingforce').innerHTML += content;
}

function processChunk(content) {
  // Process and display the content as needed
  const cleanedContent = cleanContent(content);
  updateGuidingForceHTML(cleanedContent);
}

function cleanContent(content) {
  // Perform your string cleaning here
  return content.replace(/[{}'"]+/g, '').replace(/-/g, '<br>-');
}


// Send a message to the server
const sendMessage = () => {
  // Assuming `selectedModel` holds the model choice ('1', '2', or '3') 
  let messageContent;
  
  // Trim the `sentMessagewithHighlightParts` to ensure it's not just whitespace
  const trimmedHighlight = sentMessagewithHighlightParts.trim();

  // Check if the highlight part exists and has content
  if (trimmedHighlight) {
    // If highlight exists and has content, use it as the message
    messageContent = trimmedHighlight;
  } else {
    // If no highlight, use the entire text content
    messageContent = guidingForceRef.current.innerText.trim(); // Use innerText to avoid sending HTML
  }

  // Construct the payload with separate model choice and message content
  const payload = {
    modelChoice: selectedModel, // Convert model choice to string to ensure consistency in data types
    message: messageContent,
    email: email
  };
  
  // Send the payload to the server as a JSON string
  socket.send(JSON.stringify(payload));

  // Reset the highlight parts state after sending the message
  setSentMessagewithHighlightParts("");
};


// Create a Y document

const [key, setKey] = useState(0); // Used to force reinitialize the editor component
let yText;
const newYDoc = new Y.Doc();
const [isActiveConnection, setIsActiveConnection] = useState(false);
let serverUrl = 'wss://guidedpen.com/API-YJSCollab';
const User = localStorage.getItem('User');
let ydocRef = useRef(new Y.Doc()); // Y.Doc instance
const [provider, setProvider] = useState(() => {
  // Correctly access the Y.Doc instance using ydocRef.current
  const initialProvider = new WebsocketProvider(serverUrl, uuid, ydocRef.current);
  return initialProvider;
});



// Editor initialization
// Editor initialization
const editor = useEditor({
  extensions: [
    StarterKit,
    StronglyMark,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    Color.configure({
      types: ['textStyle'],
    }),
    Document,
    Text,
    TextStyle,
    Color,
    History,
    ...isLoggedIn ? [
      Collaboration.configure({
        document: ydocRef.current,
      }),
      CollaborationCursor.configure({
        provider,
        user: {
          name: User,
          color: '#ff6347',
        },
      }),
    ] : []  // Only add collaboration extensions if logged in
  ],
  content: '',
}, [key]);

const resetEditor = () => {
  setKey(prev => prev + 1); // Increment the key to force remount
};


function updateGuidingforceHeight() {
  const proseMirror = document.querySelector('.ProseMirror');
  if (proseMirror) {
    const editorHeight = proseMirror.offsetHeight;
    const guidingforceElement = document.getElementById('guidingforce');
    if (guidingforceElement && editorHeight) {
      guidingforceElement.style.minHeight = `${editorHeight - 56}px`;
      //console.log('Updated min-height for #guidingforce:', guidingforceElement.style.minHeight);
      
      
      if (editorHeight > 500) {
        document.getElementById('h1').style.display = 'none';
      }
      // Check if the height exceeds 1000px and clear interval if true
      if (editorHeight > 1000) {
        //console.log('Editor height exceeds 1000px, stopping updates.');
        clearInterval(heightUpdateInterval);
        document.getElementById('guidedpencontainer').style.position = 'absolute';
      }
    }
  }
}

const heightUpdateInterval = setInterval(updateGuidingforceHeight, 5500);

// Make sure to provide a way to clear the interval outside this scope if needed
// This is necessary, for example, when the component unmounts in a React environment.
function cleanUp() {
  clearInterval(heightUpdateInterval);
}



//This needed so it doesnt interfer with my payment modal
const [isPayMenuVisible, setIsPayMenuVisible] = useState(false);
  // Editor selection update effect
  useEffect(() => {
    if (editor && (!isModalOpen && !showInviteModal)) {
      const handleSelectionUpdate = () => {
        const { from, to, empty } = editor.state.selection;
  
        if (!from) {
          setHighlightedText('');
        } else {
          const selectedText = editor.state.doc.textBetween(from, to, ' ');
          setHighlightedText(selectedText);
        }
      };
  
      editor.on('selectionUpdate', handleSelectionUpdate);
  
      return () => {
        editor.off('selectionUpdate', handleSelectionUpdate);
      };
    }
  }, [editor, isModalOpen, showInviteModal]);
  
  useEffect(() => {
    if (!isModalOpen && !showInviteModal & !isModalProfileOpen & isSharedModalOpen) {  //MODALS NEED TO BE ADDED HERE, OTHERWISE CONTENTS BECOME UNCLICKABLE
      const handleMouseDown = () => setIsMenuVisible(false);
      const handleMouseUp = () => {
        if (editor && !editor.state.selection.empty) {
          setIsMenuVisible(true);
        }
      };
  
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mouseup', handleMouseUp);
  
      return () => {
        document.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [editor, isModalOpen, showInviteModal]);



  const togglehandleDrop = (beatStyleName) => {
    console.log('togglehandleDrop')
  };
  const listRef = useRef();
  const openingSceneRef = useRef();
  const middleSceneRef = useRef();


  useEffect(() => {
    if (isSortableLoaded && sortableRef.current) {
      const containers = document.querySelectorAll('.sortable-list');
      containers.forEach(container => {
        new sortableRef.current(container, { // Use the stored Sortable module
          group: 'shared',
          animation: 150,
          onEnd: (evt) => {
            console.log('SortableJS event:', evt);
             handleDrop(evt); ///Assuming you have this function defined elsewhere
          },
        });
      });
    }
  }, [isSortableLoaded, togglehandleDrop]); // Depend on isSortableLoaded and togglehandleDrop



  useEffect(() => {
    guidingForceInput.current = document.getElementById('guidingforce');
  }, []);


  const undoStack = useRef([]);
  const redoStack = useRef([]);

  const replaceHighlightedText = () => {
    if (editor) {
      
      // Get the contents from the element with ID "guidingforce"
      const guidingforceContent = guidingForceInput.current.innerText;

      // Replace the highlighted text in the editor with the contents from "guidingforce"
      const { from, to } = editorselection;
      editor.view.dispatch(
        editor.view.state.tr
          .delete(from, to)
          .insertText(guidingforceContent, from)
      );

      // Store the current content in the undo stack
      undoStack.current.push(guidingforceContent);

      // Empty the content of the div with ID "guidingforce"
      guidingForceInput.current.innerText = '';

      //Show undo button
      document.getElementById('undo').style.display = 'inline-block';
      document.getElementById('undo').style.visibility = 'visible';
      document.getElementById('replaceLeft').style.visibility = 'hidden';
      document.getElementById('replaceLeftmagic').style.visibility = 'hidden';

                  //Remove highlighting
                  //editor.chain().selectAll().unsetColor().blur().run();
                  editor.commands.unsetColor();
      
    }
  };

  const undo = () => {
    if (undoStack.current.length > 0) {
      const previousState = undoStack.current.pop();

      // Store the current content in the redo stack
      redoStack.current.push(guidingForceInput.current.innerText);

      // Set the content of the div to the previous state
      guidingForceInput.current.innerText = previousState;

     // editor.chain().undo().run();
     undoEditor();

      //Hide Undo button
      document.getElementById('undo').style.display = 'none';
      document.getElementById('replaceLeft').style.visibility = 'visible';
      document.getElementById('replaceLeftmagic').style.visibility = 'visible';
      
    }
  };

  
// Lookup AI note for chapter
const lookupAINote = async (writingsIdentifier) => {
  try {
    // Retrieve noteIdentifier from local storage
    const storedNoteIdentifier = localStorage.getItem('noteIdentifier');

    const response = await fetch('https://guidedpen.com/API-GuidedPen/lookupAINote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ writingsIdentifier }),
    });

    const data = await response.json();

    if (data.success) {
      // Sort notes by LastModified in descending order
      const sortedNotes = data.notes.sort((a, b) => new Date(b.LastModified) - new Date(a.LastModified));

      setNotes(sortedNotes);

      if (sortedNotes.length > 0) {
        // Use the note saved in localStorage if it matches any in the sortedNotes
        const noteToDisplay = storedNoteIdentifier
          ? sortedNotes.find((note) => note.NoteIdentifier === storedNoteIdentifier)?.Note || ''
          : sortedNotes[0].Note;

        setGuidingForceContent(noteToDisplay);

        // Set the selected note index based on the stored note identifier
        if (storedNoteIdentifier) {
          const storedNoteIndex = sortedNotes.findIndex((note) => note.NoteIdentifier === storedNoteIdentifier);
          if (storedNoteIndex !== -1) {
            setSelectedNoteIndex(storedNoteIndex);
          }
        } else {
          // If there's no stored note identifier, set the selected index to 0
          setSelectedNoteIndex(0);
        }
      } else {
        console.log('No AI Notes available for this chapter.');
        setGuidingForceContent('');
      }
    } else {
      // Handle the case where the request was not successful
      console.error('Error fetching AI notes:', data.message);
    }
  } catch (error) {
    // Handle any network or parsing errors
    console.error('Error fetching AI notes:', error.message);
  }
};

// New note button
const handleNewNote = () => {
//editor.chain().selectAll().unsetColor().run();
editor.commands.unsetColor();
document.getElementById('guidingforce').innerHTML = '';

  const newNoteIdentifier = uuid(); // Assuming you have a function to generate a new UUID (uuid() is just a placeholder)

  // Create a new empty note
  const newEmptyNote = {
    WritingsIdentifier: WritingsIdentifier, // Replace with your actual WritingsIdentifier
    Note: '',
    NoteIdentifier: newNoteIdentifier,
  };



  // Clear the guiding force content
  setGuidingForceContent('');

  // Set the new note identifier
  setNoteIdentifier(newNoteIdentifier);


    // // Update the notes state with the new empty note
    // setNotes((prevNotes) => {
    //   const updatedNotes = [...prevNotes, newEmptyNote];
      
    //   // Set the selected note index to the index of the newly created note
    //   setSelectedNoteIndex(updatedNotes.length - 1);
  
    //   return updatedNotes;
    // });
};


  


// Modify your useEffect to set default beat style on load
useEffect(() => {
  const checkView = localStorage.getItem('View');
  if (!checkView) {
    localStorage.setItem('View', 'BeatBoard');
    setLayoutMode(true);
    // Automatically select Save the Cat and show its beats
    const saveTheCatStyle = beatStyles.find(style => style.BeatStyle === 'Save the Cat');
    if (saveTheCatStyle) {
      setSelectedBeatStyle(saveTheCatStyle);
      setBeatLayoutMode(true);
      setGlobalBeatSheetSelected('Save the Cat');
    }
  }
}, []);


  // Generate the identifier when the component mounts
  useEffect(() => {
    setWritingsIdentifier(uuid);
  }, []); // The empty dependency array ensures this effect runs only once when the component mounts

  //Generate note identifer, if non exists
  useEffect(() => {
    // Generate NoteIdentifier if it doesn't exist
    if (!noteIdentifier) {
      setNoteIdentifier(uuid);
    }
  }, [noteIdentifier]);


  const setChapterTitleInEditor = (title) => {
    if (editor) {
      //const transaction = editor.chain().focus().setParagraph().clearContent().insertContent(`<h1>${title}</h1>`).run();
      const transaction = editor.chain().focus().clearContent().insertContent(`${title}`).run();
     // editor.onTransaction(transaction);
    }
  };

  const setChapterBody = (body) => {
    if (editor) {
      editor.chain().focus().clearContent().insertContent(body).run();
    }
  };




const selectedChapter = (chapter) => {


    if (selectedChapter) {
      const chaptersWithDetails = chapters.map((chapter) => ({
        ...chapter,
        beatsGroup: chapter.beatsGroup || '',
        chapterSummary: chapter.chapterSummary || '',
      }));
    
      console.log('passChapters', chaptersWithDetails);
      setChapterDetailsArray(chaptersWithDetails);

      //set whats been passed from localStorage Chapter page
      setorderingStr(localStorage.getItem('WritingsOrdering'));

    }
    
console.log('Checking selected chapter ', selectedChapter)

    // if (selectedChapter) {
    // // Set the local state with selected chapter details
    // setLocalSelectedChapter({
    //   title: selectedChapter.chapterName,
    //   content: selectedChapter.chapterContent,
    //   identifier: selectedChapter.WritingsIdentifier,
//     // });

//     // Set the chapter title in the editor
//      setChapterTitleInEditor(selectedChapter.chapterName);
//  //    setChapterBody(selectedChapter.chapterContent);
//      setWritingsIdentifier(selectedChapter.WritingsIdentifier);
//     // localStorage.setItem('writingsIdentifier', chapterDetailsArray.WritingsIdentifier);

//     // Lookup AI Notes
//     lookupAINote(selectedChapter.WritingsIdentifier);

// // Change the color of the selected chapter
    // const chapters = document.querySelectorAll('.chapters');
    // chapters.forEach((chapter) => {
    //   if (chapter.dataset.identifier === selectedChapter.WritingsIdentifier) {
    //     chapter.style.color = 'red';
    //   } else {
    //     chapter.style.color = ''; // Reset the color for other chapters
    //   }
    // });
  //}



}




  // Button 1
const aiButtonClick1 = () => {
  aiButtonClick();
};

// Button 2 with additional text
const aiReword = () => {
  aiButtonClick(' reword this');
};

// Button 2 with additional text
const aiExpand = () => {
  aiButtonClick(' expand on this');
};

// Button 2 with additional text
const aiBrainStorm = () => {
  aiButtonClick(' brainstorm this');
};

const aiPlaceText = () => {
  aiButtonClick(' ');
};

const aiThesaurus = () => {
  aiButtonClick(' Thesaurus');
};







//Store the to and from highlight value from the editor
const [editorselection, setEditorSelection] = useState({ from: null, to: null, empty: null });

useEffect(() => {
  console.log('editorselection', editorselection);
}, [editorselection]);

  const aiButtonClick = (addText = '') => {

    const { from, to, empty } = editor.state.selection;
    setEditorSelection({ from, to, empty });
    

    console.log('from', from);
    console.log('to', to);
    console.log('empty', empty);
    console.log('editorselection', editorselection)

    // Check if there is a highlighted text
    if (empty) {
      chapterHighlight();
     } else {
      
     }

    const guidingForceInput = document.getElementById('guidingforce');
    setLastHighlightedText('');
    let selectedRange = null;
  
    // Remove all child nodes (clear content)
    guidingForceInput.innerHTML = '';
    guidingForceInput.focus();
  
   
    selectedRange = { from, to };
  
    //editor.chain().selectAll().unsetColor().run();
    editor.commands.unsetColor();

  
    if (!textareaValue.includes('@highlighted')) {
      setLastHighlightedText('@highlighted');
      setTextareaValue((prevValue) => prevValue + ` @highlighted ${addText}`);
    }
  
    if (guidingForceInput) {
 
      guidingForceInput.focus();
  
      // Wrap @highlighted text in a span without any styles
      const newTextNode = document.createElement('span');
      newTextNode.appendChild(document.createTextNode('@highlighted --> '));
      guidingForceInput.appendChild(newTextNode);
  
      // Append additional text if provided
      if (addText) {
        const newTextNode2 = document.createElement('span');
        newTextNode2.appendChild(document.createTextNode(addText));
        guidingForceInput.appendChild(newTextNode2);
      }
  
      // Set the cursor at the end
      const range = document.createRange();
      range.setStartAfter(newTextNode);
      range.collapse(true);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
 
    // Set color immediately (not in setTimeout)
    if (selectedRange) {
      editor.chain().setTextSelection(selectedRange).setColor('tomato').run();
      moveCursorToEnd();
    }

    setEditorSelection('');
    document.getElementById('replaceLeft').style.visibility = 'visible';
    document.getElementById('replaceLeftmagic').style.visibility = 'visible';
    
      
    moveCursorToEnd();
  };
  

  const moveCursorToEnd = () => {
    const guidingForceInput = document.getElementById('guidingforce');
    if (guidingForceInput) {
        // Focus the input to ensure cursor operations apply to it
        guidingForceInput.focus();

        // Delay the cursor movement slightly to ensure DOM updates have rendered
        setTimeout(() => {
            const range = document.createRange();
            range.selectNodeContents(guidingForceInput);
            range.collapse(false); // false to collapse the range to its end, moving cursor there

            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);

            // Additionally, scroll into view if the content is overflowed
            guidingForceInput.scrollTop = guidingForceInput.scrollHeight;
        }, 0); // Start with 0ms, adjust if necessary based on behavior
    }
};

  
  
  const replaceHighlight = (text, matchedText) => {
    // Dynamic replacement based on the matchedText
    if (matchedText) {
      // Check if matchedText is '@highlighted' or '@Chapter', or a dynamic pattern
      if (matchedText.toLowerCase() === '@highlighted') {
        return '<span style="color: tomato;" id="highlighted">@highlighted</span>';
      } else if (matchedText.toLowerCase() === '@Chapter') {
        return '<span style="color: skyblue;" id="chapter">@Chapter </span>';
      } else {
        // For dynamic patterns like '@new1', keep the text as is, just apply sky blue color
        return `<span style="color: skyblue;">${matchedText}</span>`;
      }
    }
    
    // Fallback for the entire text (this might not be needed depending on your use case)
    const highlightedText = text.replace(/@highlighted/g, '<span style="color: tomato;" id="highlighted">@highlighted</span>');
    const chapterText = highlightedText.replace(/@Chapter/g, '<span style="color: skyblue;" id="chapter">@Chapter </span>');
    
    return chapterText;
  };
  
  
  





  
  

  const unSetfunction  = () => { 
      //editor.chain().selectAll().unsetColor().run();
      editor.commands.unsetColor();
      document.getElementById('unset').style.display = 'none';

      // Remove all from guidance div
      const guidingForceInput = document.getElementById('guidingforce');          
      guidingForceInput.innerHTML = '';
      const selectedText = '';
      setHighlightedText('');
      editor.chain().setTextSelection('').run();
      document.getElementsByClassName('ProseMirror')[0].style.border = '1px solid rgb(204, 204, 204)';
      document.getElementById('replaceLeft').style.visibility = 'visible';
      
      //Reset the selection thats saved from editor (from, to)
      setEditorSelection('');

  }

const cleanText  = () => { 
  //editor.chain().selectAll().unsetColor().run();
  editor.commands.unsetColor();
  //Reset the selection thats saved from editor (from, to)
  document.getElementById('guidingforce').innerHTML = '';
}

const undoEditor  = () => { 
  editor.commands.undo()
  //Reset the selection thats saved from editor (from, to)
}



  

  const stripHtml = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "";
  };
  
  const handleSubmit = async () => {
    // Check if the accessType is 'none' and usage exceeds 5
    if ((!userIsOwner) && usage > 100) {
      toggleModal();  // This opens the modal suggesting they can't perform the action
      return;         // Exit the function to prevent further execution
    }
  
      


    let logValueWithHtml = stripHtml(highlightedText);
    let logValueWithChapter = stripHtml(logValueWithHtml);
  
    console.log('logValueWithChapter', logValueWithChapter);
    console.log('setChapter here', setChapter);
    console.log(selectedChapter);
  
    if (atChapterWasUsed === true) {
      logValueWithHtml = stripHtml(chapterContent);
      logValueWithChapter = stripHtml(logValueWithHtml);
    }

    // Handle multiple @chapter names used/selected
    if (selectedSuggestions && selectedSuggestions.length > 0) {
      const combinedChapterContent = await fetchChapterDetails(selectedSuggestions);
  
      // Now combinedChapterContent contains all the chapter content concatenated into a single string
      logValueWithChapter += combinedChapterContent;

    }

  
    const guidingForceInput = document.getElementById('guidingforce');
    const guidingForceText = guidingForceInput ? stripHtml(guidingForceInput.innerHTML) : '';
    const finalLogValue = `${logValueWithChapter} ${guidingForceText}`;
  
    const newfinalLogValue = finalLogValue.replace('@highlighted', '').replace('@Chapter', '');
    setSentMessagewithHighlightParts(newfinalLogValue);
  
    if (guidingForceInput) {
      //guidingForceInput.innerHTML = '';
      guidingForceInput.innerHTML += '<br><br>';
    }
  
    setSelectedSuggestions([]); // Reset after processing
  };
  

// Assuming this is inside a React functional component
useEffect(() => {
  //alert('sentMessagewithHighlightParts: ', sentMessagewithHighlightParts);
  if (sentMessagewithHighlightParts.length > 1) {
   // alert('hereeee')
    sendMessage(sentMessagewithHighlightParts); // Corrected variable name
  }
}, [sentMessagewithHighlightParts]);
  

  const chapterHighlight = () => {

    // Use setTimeout to ensure the selectAll command finishes before the rest of the code
    setTimeout(() => {
      document.getElementsByClassName('ProseMirror')[0].style.border = '2px skyblue solid';
      editor.chain().selectAll().run();
  
      // Get the current selection range
      const { from, to, empty } = editor.state.selection;
  
      const highlightedText = editor.state.doc.textBetween(from, to, ' ');
  
      // Update the editor content
      setSetChapter(highlightedText);

  
      // Pass only the word '@Chapter' to the guidingforce div with blue color
      const guidingForceInput = document.getElementById('guidingforce');
      if (guidingForceInput) {
        guidingForceInput.innerHTML = replaceHighlight('@Chapter') + '<span> --></span>';
        setTimeout(() => {
          moveCursorToEnd();
          //alert('moving cursor to the end');
      }, 0); // Adjust this delay as well if needed
      }
      


    }, 0);
  };




  const [suggestions, setSuggestions] = useState([]);
  const autoCompleteBoxRef = useRef(null); // Reference to your autocomplete box
  const guidingForceInputRef = useRef(null); // Reference to your input
  
  useEffect(() => {
    // Log the value of setChapter after it has been updated
    console.log('setChapter', setChapter);
  }, [setChapter]);
  

  const AICommand = (command) => {

    // Use setTimeout to ensure the selectAll command finishes before the rest of the code
    setTimeout(() => {
      editor.chain().selectAll().run();
  
      // Get the current selection range
      const { from, to } = editor.state.selection;
  
      // Get the highlighted text
      const highlightedText = editor.state.doc.textBetween(from, to, ' ');
  
      // Update the editor content
      setSetChapter(highlightedText);
  
      // Pass only the word '@Chapter' to the guidingforce div with blue color
      const guidingForceInput = document.getElementById('guidingforce');
      if (guidingForceInput) {
        guidingForceInput.innerHTML = replaceHighlight('@Chapter ') + ' ' + command;
      }
      moveCursorToEnd();

      setTimeout(() => {
        //trigger submit
          document.getElementById('guidedsubmit')?.click();
      }, 1000);

    }, 0);
  };
  

// Assuming this is within a functional component

const [showAutoCompleteBox, setShowAutoCompleteBox] = useState(false);
const [identifiedChapters, setIdentifiedChapters] = useState([]);

// A simple debounce function
const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

useEffect(() => {
  // Wrapped handleInputChange with debounce
  const handleInputChange = debounce(() => {
    if (editor && editor.chain) {
      const pattern = /@(\w+)/g;
      // Your existing logic
    }
  }, 250); // Adjust the debounce time (in milliseconds) as needed

  const inputElement = guidingForceInputRef.current;
  if (inputElement) {
    inputElement.addEventListener('input', handleInputChange);
  }

  return () => {
    if (inputElement) {
      inputElement.removeEventListener('input', handleInputChange);
    }
  };
}, [chapterDetailsArray, editor]); 


useEffect(() => {
  const handleKeyUp = (e) => {
    const triggerIndex = guidingForceInputRef.current.innerText.lastIndexOf('@');

    if (triggerIndex !== -1) {
      const query = guidingForceInputRef.current.innerText.substring(triggerIndex + 1);
      const filteredSuggestions = chapterDetailsArray.filter(chapter => 
        chapter.chapterName.toLowerCase().startsWith(query.toLowerCase())
      ).map(chapter => chapter.chapterName);

      setSuggestions(filteredSuggestions);
      setShowAutoCompleteBox(filteredSuggestions.length > 0);
    } else {
      setShowAutoCompleteBox(false);
    }
  };

  guidingForceInputRef.current.addEventListener('keyup', handleKeyUp);

  return () => {
 //   guidingForceInputRef.current.removeEventListener('keyup', handleKeyUp);
  };
}, [chapterDetailsArray]);


const handleSuggestionClick = (suggestion) => {
  let content = guidingForceInputRef.current.innerHTML; // Use innerHTML to allow HTML styling

  // Find the last '@' symbol and replace everything after it with the suggestion and a space
  const lastAtIdx = content.lastIndexOf('@');
  if (lastAtIdx !== -1) {
    // Build the new content with the suggestion styled in sky blue
    // Ensure no trailing '@' and add a non-breaking space '&nbsp;' to maintain the space visually
    content = content.substring(0, lastAtIdx) + 
              `<span style="color: skyblue;">@${suggestion}</span>` + 
              '&nbsp;' + // Use &nbsp; for a non-breaking space after the suggestion
              content.substring(lastAtIdx + 1).replace(/\w+$/, '') + 
              ' '; // Ensure there's a space after the inserted suggestion

    // Update the content using innerHTML to preserve the new styling
    guidingForceInputRef.current.innerHTML = content;
    setGuidingForceContent(content); // Update your state if necessary
  }

  setShowAutoCompleteBox(false); // Hide suggestions box


  // Save their selection from the dropdown, for use in the handleSubmit
  const chapterDetails = chapterDetailsArray.find(chapter => chapter.chapterName === suggestion);
  if (chapterDetails) {
    setSelectedSuggestions(prevSelections => [
      ...prevSelections,
      {
        name: chapterDetails.chapterName,
        id: chapterDetails.WritingsIdentifier, // Assuming 'WritingsIdentifier' holds the ID
      }
    ]);
    console.log('selectedSuggestions', selectedSuggestions);
  }


    // Delay to allow the DOM to update and then move the cursor to the end
    setTimeout(() => {
      guidingForceInputRef.current.focus();
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(guidingForceInputRef.current);
      range.setEndAfter(guidingForceInputRef.current.lastChild); // Ensure the cursor is placed after the last element
      range.collapse(false); // Move to end
      sel.removeAllRanges();
      sel.addRange(range);
    }, 0); // Reduced delay for better responsiveness
  
};



//Async lookup the chapters using writingLookupFromASignupChapterDropdown
const fetchChapterDetails = async (identifiedChapters) => {
  const fetchPromises = identifiedChapters.map(chapter =>
    writingLookupFromAtSignChapterDropdown(chapter)
  );

  const chaptersDataResults = await Promise.allSettled(fetchPromises);

  console.log('chaptersDataResults', chaptersDataResults);

  // Filter for fulfilled promises and aggregate chapterContent
  const aggregatedContent = chaptersDataResults
    .filter(result => result.status === 'fulfilled')
    .map(result => result.value) // result.value here is the chapterContent from each successful fetch
    .join(' '); // Combine all chapter contents into a single string

  return aggregatedContent;
};




// Lookup a single chapter's details based on its identifier
const writingLookupFromAtSignChapterDropdown = async (suggestion) => {
  try {
    const response = await fetch('https://guidedpen.com/API-GuidedPen/writingLookup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ WritingsIdentifier: suggestion.id }),
    });

    const data = await response.json();

    if (data.success && data.chapterDetails.length > 0) {
      // Assuming you're interested in the first chapter's content
      const firstChapterContent = data.chapterDetails[0].chapterContent;
      return firstChapterContent;
    } else {
      console.error('Failed to fetch chapter details for:', suggestion.name);
      return '';
    }
  } catch (error) {
    console.error('Error fetching chapter details for:', suggestion.name, error);
    return '';
  }
};






const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};

const handleShareKeyDown = (event) => {
  console.log('Key pressed:', event.key); // Log the key to ensure this function is triggered
  if (['Enter', 'Tab', ',', ' '].includes(event.key)) {
    event.preventDefault();
    console.log('Adding email:', document.getElementById('emailsharewithinput').value);
    handleAddEmail(); // Log inside handleAddEmail to check it's being called
    document.getElementById('emailsharewithinput').value = '';
  }
};


const removeEmail = async (emailToRemove) => {
  if (!emailToRemove) return; // Guard clause if no email is provided

  // Update local state to immediately reflect UI changes
  const updatedEmails = emails.filter(email => email !== emailToRemove);
  const updatedAccessLevels = {...accessLevels};
  delete updatedAccessLevels[emailToRemove];
  setEmails(updatedEmails);
  setAccessLevels(updatedAccessLevels);

  // Prepare to send the request to the backend
  const authToken = localStorage.getItem('Auth');  // Retrieve the auth token from local storage
  const currentUserEmail = email;  // The globally defined email variable

  try {
    const response = await fetch('https://guidedpen.com/API-GuidedPen/removeInvite', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authToken + ',' + currentUserEmail // Custom format without using 'Bearer'
      },
      body: JSON.stringify({ targetEmail: emailToRemove, worksIdentifier: worksIdentifier })
    });

    const responseData = await response.json();
    if (responseData.success) {
      console.log('Email removed successfully:', responseData);
      alert('Email removed successfully!');
      workLook(worksIdentifier, email); // This will re-pull it
    } else {
      console.error('Failed to remove email:', responseData.message);
      alert('Failed to remove email: ' + responseData.message);
      // Optionally roll back local state changes if the server fails to process the request
    }
  } catch (error) {
    console.error('Error removing email:', error);
    alert('An error occurred while removing the email.');
    // Optionally roll back local state changes if there is an error
  }
};


const updateAccessLevel = (targetEmail, level) => {
  const authToken = localStorage.getItem('Auth');  // Retrieve the auth token from local storage
  const currentUserEmail = email;  // The globally defined email variable

  const payload = {
    targetEmail: targetEmail,  // Email of the user whose access level is being changed
    newAccessLevel: level,
    worksIdentifier: worksIdentifier  // Your work identifier
  };

  fetch('https://guidedpen.com/API-GuidedPen/UpdateAccessLevel', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authToken + ',' + currentUserEmail  // Custom format without using 'Bearer'
    },
    body: JSON.stringify(payload)
  })
  .then(response => response.json())
  .then(data => {
    if (data.success) {
      console.log('Access level updated successfully:', data);
      alert('Access level updated successfully!');
      workLook(worksIdentifier, email); // This will re-pull it
    } else {
      console.error('Failed to update access level:', data.message);
      alert('Failed to update access level: ' + data.message);
    }
  })
  .catch(error => {
    console.error('Error updating access level:', error);
    alert('An error occurred while updating access level.');
  });
};



const handleAddEmail = async () => {
  const inputElement = document.getElementById('emailsharewithinput');
  const inputValue = inputElement.value.trim();

  if (inputValue && isValidEmail(inputValue) && !emails.includes(inputValue)) {
    setEmails(prevEmails => [...prevEmails, inputValue]);
    setAccessLevels(prevLevels => ({...prevLevels, [inputValue]: 'edit'}));
  }
};

useEffect(() => {
  // This code runs after `emails` or `accessLevels` updates
  console.log('Emails or Access Levels changed:', emails, accessLevels);
  // Any other side effects related to updating these states can go here
}, [emails, accessLevels]); // Dependency array, re-run the effect when these states change




const sendShareToBackend = () => {
  // Step 1: Set as Processing
  setIsProcessing(true);

  // Prepare updated data
  let updatedEmails = [...emails];
  let updatedAccessLevels = {...accessLevels};
  const inputValue = emailInputRef.current.value.trim();
  if (inputValue && isValidEmail(inputValue) && !emails.includes(inputValue)) {
    updatedEmails.push(inputValue);
    updatedAccessLevels[inputValue] = 'edit'; // Defaulting to 'edit' access
    emailInputRef.current.value = ''; // Clear input field
  }

  // Update state asynchronously
  setEmails(updatedEmails);
  setAccessLevels(updatedAccessLevels);

  // Step 3: Wait 2 Seconds
  setTimeout(() => {
    // Construct the payload using updated local copies
    const payload = {
      worksIdentifier, 
      sharedDetails: updatedEmails.map(email => ({
        email,
        accessLevel: updatedAccessLevels[email]
      }))
    };

    // Step 4: Fire the POST
    fetch('https://guidedpen.com/API-GuidedPen/SendShareDocument', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      alert('Book shared! They will show in your list once they accept your invite.');
      setSharedModalOpen(false); // Close the modal after successful sharing
      setEmails([]); // Clear the emails list
      setAccessLevels({}); // Clear the access levels
      emailInputRef.current.value = ''; // Ensure input field is cleared
    })
    .catch(error => {
      console.error('Error:', error);
      alert('Error sharing document.');
    })
    .finally(() => {
      setIsProcessing(false); // Reset processing indicator
    });
  }, 2000); // Delay of 2 seconds
};

const toggleSharedModal = () => {
  setSharedModalOpen(!isSharedModalOpen);
};

const closeSharedFunction = () => {
  setSharedModalOpen(false);
  setEmails([]); // Clear the emails list
  setAccessLevels({}); // Clear the access levels
  if (emailInputRef.current) {
    emailInputRef.current.value = ''; // Clear the input field
  }
};

// Add event listener to close modal on outside click
  // Add event listener to close modal on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Ensure the modal is open before considering a close action
      if (isSharedModalOpen && !document.querySelector('.shared-modal-content').contains(event.target)) {
        closeSharedFunction();
      }
    };

    // Add when the modal is open and remove when closed
    if (isSharedModalOpen) {
      window.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSharedModalOpen]);



  const handleUnsuccessfulLogin = () => {
    console.log('User not logged in.');
    const workTitleInput = document.getElementById('WorkTitleInput');
    if (workTitleInput) {
      workTitleInput.innerText = 'My new book...';
    }
    setWorksTitle('My new book');
    setChapterContentToSet('<h1>My Chapter One...</h1><p><br>However you choose to start your story,<br>with <strongly><b>GuidedPen</b></strongly>, your story starts here...</p><p></p>');
  };

  const [isContentSet, setIsContentSet] = useState(false); 
  useEffect(() => {
    // Check if editor is ready and content is set, and ensure the content is set only once
    if (editor && chapterContentToSet && !isContentSet) {
      console.log('ContentSetHERE1')
      setTimeout(() => {
        const textContent = editor.getText();
        const textLength = textContent.length;
        if (textLength < 10 || !textLength || textLength === 0) {
           //editor.chain().focus().setContent(chapterContentToSet, true).run();
           editor.commands.setContent(chapterContentToSet);
          // alert('here')
           setIsContentSet(true); // Set the flag to true to prevent re-insertion
        }
      }, 10);
    }
  }, [editor, chapterContentToSet, isContentSet]);

  const fetchInitialData = async () => {
    const query = window.location.search;
    const fixedQuery = query.replace(/\+/g, '%2B');
    const params = new URLSearchParams(fixedQuery);

    const invite = params.get('invite');
    const email = params.get('e');
    const authorCode = params.get('ac');

    if (invite && email) {
      const correctedEmail = decodeURIComponent(email);
      setInviteDetails({ invite, email: correctedEmail });
      fetchBookDetails(invite); // Assuming this sets some state
      setShowInviteModal(true); // Show modal if invite is present
      return; // Exit after handling invite to avoid default login flow
    }

    if (authorCode) {
      fetchAuthorOutreachDetails(authorCode); // Assuming this sets some state or effects
      return; // Exit after handling author outreach to avoid default login flow
    }

    // Default user data fetching logic if no specific params are present
    const storedEmail = localStorage.getItem('Email');
    if (storedEmail && !isLoggedIn) {
        try {
          await userLook(storedEmail);
          // additional success logic here if needed
        } catch (error) {
          handleUnsuccessfulLogin();
        }
      } else {
        handleUnsuccessfulLogin();
      }

        // Handle beat or overview setting independently of login status
        const checkbeatoroverview = localStorage.getItem('beatorbeatoverview');
        if (!checkbeatoroverview) {
          setbeatorbeatoverviewmode(checkbeatoroverview);
      }

      if (!isLoggedIn || !storedEmail){
      const worksIdentifier = uuid();
      localStorage.setItem('works', worksIdentifier);
      setWorksIdentifier(worksIdentifier);
      return;
      }
  };

  // useEffect to initialize on mount
  useEffect(() => {
    fetchInitialData();
  }, []);
  
  const [authorEmail, setAuthorEmail] = useState('');
  const fetchAuthorOutreachDetails = (authorCode) => {
    console.log("Fetching author outreach details for code:", authorCode); // Log to confirm the function is called
    fetch('https://guidedpen.com/API-GuidedPen/authorOutreach', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ AuthorCode: authorCode })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success && data.data.length > 0) {
        const authorData = data.data[0];
        setAuthorOutreachData(authorData);  // Directly use the data fetched  // Set the first item of the array
        setShowInviteModal(true); // Set modal to show if data is fetched successfully
        setAuthorEmail(authorData.Email); // Set authorEmail state here


        //setchapterContent(authorOutreachData.Title);
   // Use the fetched data directly since the state update won't reflect until next render
   setTimeout(() => {
    let localWritingsIdentifier = WritingsIdentifier;
    if (!localWritingsIdentifier) {
      localWritingsIdentifier = uuid();  // Generate new UUID
      setWritingsIdentifier(localWritingsIdentifier);  // Assuming setWritingsIdentifier updates the state or a global
    }
    setChapterContentToSet(`<h1>${authorData.Title} 2.0</h1><p>Your new story begins here...</p>`);
    setChapterName(authorData.Title);
    setChapterBody(authorData.Title);

      setChapterDetailsArray([
        {
          chapterName: 'Chapter 1',
          WritingsIdentifier: WritingsIdentifier,  // Use the newly generated UUID here if appropriate
          chapterSummary: '',
          beatsGroup: 'Introduction',
          chapterContent: '<h1>${authorOutreachData.Title}  2.0</h1><p>Your new story begins here...</p>'
        }
      ]);
      const { sortedChapterDetails } = reorderAfterWritingLookup(chapterDetailsArray, WritingsIdentifier);  
      setChapters(sortedChapterDetails);

      }, 2000);  // setTimeout with 0 to delay execution until stack is clear, if needed




      } else {
        console.log("No data or failed to fetch author outreach details");
        setShowInviteModal(false); // Optionally handle no data/failure case
      }
    })
    .catch(error => {
      console.error('Error fetching author outreach details:', error);
    });
  };

  

  const fetchBookDetails = (inviteId) => {
    fetch('https://guidedpen.com/API-GuidedPen/lookupinvitedToWorks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ worksIdentifier: inviteId })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setInviteDetails(prevDetails => ({
          ...prevDetails,
          bookName: data.worksTitle, // Assuming the key is 'worksTitle' based on your backend schema
          writer: data.owner.name, // Assuming the owner object contains 'name'
          ownerImage: data.owner.image // Assuming the owner object contains 'image'
        }));
      } else {
        console.error('Failed to fetch book details:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching book details:', error);
    });
  };
  

  const handleClose = () => {
    setShowInviteModal(false);
  };

  const acceptInvite = () => {
    const inviteId = document.getElementById('inviteId').value;
    const inviteemail = document.getElementById('inviteemail').value;

    const button = document.getElementById('acceptinvitebutton');
    button.innerText = 'Processing'; // Update button text
    button.disabled = true; // Disable the button to prevent multiple submissions
  
    fetch('https://guidedpen.com/API-GuidedPen/acceptInvite', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        inviteId: inviteId,
        email: inviteemail
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        alert('You have successfully accepted the invite! Please check your email for further instructions.');
        userLook(inviteemail, data.password); // Assuming userLook is the login function
        handleClose(); // Assuming handleClose will close the modal and clear the state
        window.history.pushState({}, document.title, "/"); // Reset URL
      } else {
        alert('Failed to accept the invite: ' + data.message);
      }
    })
    .catch(error => {
      console.error('Error accepting the invite:', error);
      alert('An error occurred while accepting the invite.');
    });
  };

  const getUserAvatar = (name) => {
    return (
      <div className="avatar-placeholder" style={{
        display: 'inline-block',
        width: '30px',  // Adjust size as necessary
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#ccc',  // Choose a background color that fits your design
        color: 'white',
        textAlign: 'center',
        lineHeight: '30px',  // This should be the same as width and height for vertical alignment
        fontSize: '16px'  // Adjust font size as necessary
      }}>
        {name.charAt(0).toUpperCase()}
      </div>
    );
  };



  const acceptOutreachInvite = () => {
    const authorInviteCode = new URLSearchParams(window.location.search).get('ac');

    const button = document.getElementById('acceptoutreachbutton');
    button.innerText = 'Processing'; // Update button text
    button.disabled = true; // Disable the button to prevent multiple submissions
  
    if (!WritingsIdentifier) {
      console.error('WritingsIdentifier is not set');
      return; // Optionally, handle this case more gracefully in the UI
    }
  
    fetch('https://guidedpen.com/API-GuidedPen/acceptAuthorOutreach', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: authorOutreachData.Email,
        title: authorOutreachData.Title,
        image: authorOutreachData.Image,
        authorInviteCode: authorInviteCode,
        writingsIdentifier: WritingsIdentifier, // Now passing WritingsIdentifier
        accepted: true
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        alert('You have successfully accepted the invite! Please check your email for further instructions.');
        userLook(authorOutreachData.Email, data.password); // Logging in the user
        handleClose(); // Closing the modal
        window.history.pushState({}, document.title, "/"); // Resetting the URL
      } else {
        console.error('Failed to accept the invite:', data.message);
      }
    })
    .catch(error => {
      console.error('Error accepting the invite:', error);
    });
  };





  // Function to extract chapter name from HTML content

  const getChapterName = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const h1Element = doc.querySelector('h1');
  
    if (h1Element) {
      // Extract text content from the h1 tag
      return h1Element.textContent.trim();
    }
  
    // If no h1 tag is found, return an empty string
    return '';
  };

  const [chapterName, setChapterName] = useState(getChapterName(editor));


// Save the chapter name to the database
const saveChapterNameToDatabase = async () => {



  editor.chain().selectAll().unsetColor().blur().run();


  // Check if the user is logged in
  if (!isLoggedIn) {
    toggleModal();
    return;
  }

  // Change the button's background color to orange
  const saveButton = document.getElementById('saveChapter');
  // Instantly change the background color to orange without transition
  saveButton.style.transition = 'none'; // Disable transition for instant change
  saveButton.style.backgroundColor = 'orange';

  try {
    // Extract chapter name and content
    const editorContent = editor.getHTML();
    console.log('Editor Content:', editorContent);
    const chapterName = getChapterName(editorContent);
    console.log('Saving to DB:', chapterName);

    // Send a POST request to the backend endpoint
    const response = await fetch('https://guidedpen.com/API-GuidedPen/saveChapter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chapterName: chapterName,
        chapterContent: editorContent,
        WritingsIdentifier: WritingsIdentifier,
        beatsGroup: selectedBeats || 'Unmatched',
        WorksIdentifier: worksIdentifier // Ensure this is defined and available in your scope
      }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log('Server response:', data);

      // Re-enable the transition effect after a brief timeout, then fade back
      setTimeout(() => {
        saveButton.style.transition = 'background-color 1s ease'; // Re-enable transition
        saveButton.style.backgroundColor = '#FCF3EE'; // Start fading back after re-enabling transition
      }, 10); // This delay is very short, practically instant, to re-enable the transition

    } else {
      console.error('Failed to save chapter:', response.statusText);
    }
  } catch (error) {
    console.error('Error saving chapter:', error.message);
  }
};

// useEffect(() => {
//   // Function to be called every 5 minutes (300,000 milliseconds)
//   const intervalId = setInterval(() => {
//     console.log("Autosaving...");
//     saveChapterNameToDatabase();
//   }, 300000);

//   // Cleanup function to clear the interval when the component unmounts or dependencies change
//   return () => clearInterval(intervalId);
// }, []); // Empty dependency array ensures this runs once on mount and cleans up on unmount



const [selectedOption, setSelectedOption] = useState(0);

const handleButtonClick = () => {
  const nextOption = (selectedOption + 1) % 3; // Assuming you have 3 options
  setSelectedOption(nextOption);

  if (nextOption === 0) {
    editor.chain().focus().setParagraph().run();
  } else if (nextOption === 1) {
    editor.chain().focus().toggleHeading({ level: 2 }).run();
  } else {
    editor.chain().focus().toggleHeading({ level: 1 }).run();
  }
};


// Inside your component
const [selectedAlignment, setSelectedAlignment] = useState('left');

const handleAlignmentClick = () => {
  const nextAlignment = getNextAlignment(selectedAlignment);
  setSelectedAlignment(nextAlignment);

  // Update editor's alignment based on the nextAlignment
  editor.chain().focus().setTextAlign(nextAlignment).run();
};

// Helper function to get the next alignment option
const getNextAlignment = (currentAlignment) => {
  const alignments = ['left', 'center', 'right'];
  const currentIndex = alignments.indexOf(currentAlignment);
  const nextIndex = (currentIndex + 1) % alignments.length; // Cycle through the array
  return alignments[nextIndex];
};







//Enter on the DIV so submits
const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    if (!e.shiftKey) {
      handleSubmit(); // Your submit function
      e.preventDefault(); // Prevent the default behavior (new line) only if Shift is not pressed
    }
  }
};

useEffect(() => {
  const handleKeyPress = (event) => {
    //console.log('Key pressed:', event.key);

    // Check if the event key is 's' and the ctrl key is pressed
    if (event.key === 's' && (event.ctrlKey || event.metaKey)) {
      // Prevent the default browser behavior (e.g., saving the page)
      event.preventDefault();
      // Call the saveChapterNameToDatabase function
      saveChapterNameToDatabase();
    }

    if (event.ctrlKey && event.key === 'p') {
      event.preventDefault();
      printDocument();
    }

    // Check if Ctrl + Right arrow key is pressed
    if (event.ctrlKey && event.key === 'ArrowRight') {
      console.log('Ctrl + Right pressed!');
      
      // Fire a click event on the button with id 'replaceLeft'
      const replaceLeftButton = document.getElementById('replaceLeft');
      if (replaceLeftButton) {
        replaceLeftButton.click();
      }
      
      // Clear any active selection or formatting
      if (editor) {
        console.log('if editor is firing');
        unSetfunction();
      }
    }

    // Check if Ctrl + Left arrow key is pressed
    if (event.ctrlKey && event.key === 'ArrowLeft') {
      console.log('Ctrl + Left pressed!');
      
      // Fire a click event on the button with id 'replaceRight'
      const replaceRightButton = document.getElementById('replaceRight');
      if (replaceRightButton) {
        replaceRightButton.click();
      }
      
    }
  };

  // Add event listener when the component mounts
  window.addEventListener('keydown', handleKeyPress);

  // Clean up the event listener when the component unmounts
  return () => {
    window.removeEventListener('keydown', handleKeyPress);
  };
}, [saveChapterNameToDatabase]);




//Hide button if DIV of guidingforce is empty
const guidingForceInputCheck = useRef(null);
const [isGuidingForceNotEmpty, setIsGuidingForceNotEmpty] = useState(false);

useEffect(() => {
  guidingForceInputCheck.current = document.getElementById('guidingforce');

  // Function to handle input changes and update the state
  const handleInputChange = () => {
    const trimmedContent = guidingForceInputCheck.current.innerHTML.trim();
    setIsGuidingForceNotEmpty(trimmedContent !== '');

    // Show/hide the button based on content
    const replaceRightElement = document.getElementById('replaceLeft');
    if (replaceRightElement) {
      replaceRightElement.style.visibility = trimmedContent !== '' ? 'visible' : 'hidden';
    }
    // Show/hide the button based on content
    const UndoElement = document.getElementById('undo');
    if (UndoElement) {
      UndoElement.style.visibility = trimmedContent !== '' ? 'visible' : 'hidden';
    }

    
  };

  // Initial setup
  handleInputChange();

  // Add input event listener to dynamically update the state
  if (guidingForceInputCheck.current) {
    guidingForceInputCheck.current.addEventListener('input', handleInputChange);
  }

  // Cleanup: Remove the event listener when the component unmounts
  return () => {
    if (guidingForceInputCheck.current) {
      guidingForceInputCheck.current.removeEventListener('input', handleInputChange);
    }
  };
}, []);



const saveAINote = async () => {
  const theguidingForceContent = document.getElementById('guidingforce').innerHTML;
  const userIdentifier = email; // Replace with your actual globally defined email variable
  try {
    const response = await fetch('https://guidedpen.com/API-GuidedPen/saveAINote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        aiNote: theguidingForceContent,
        WritingsIdentifier, // Assuming WritingsIdentifier is globally defined
        NoteIdentifier: noteIdentifier,
        UserIdentifier: userIdentifier, // Added User Identifier
      }),
    });

    const data = await response.json();

    if (data.success) {
      console.log('Note saved successfully');
      fetchAndUpdateData(noteIdentifier);
      localStorage.setItem('noteIdentifier', noteIdentifier);
    } else {
      console.error('Error saving note:', data.message);
    }
  } catch (error) {
    console.error('Error saving note:', error.message);
  }
};


// Function to fetch and update data
const fetchAndUpdateData = async (noteIdentifier) => {
  try {
    const response = await fetch('https://guidedpen.com/API-GuidedPen/lookupAINote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ writingsIdentifier: WritingsIdentifier }),
    });

    const data = await response.json();

    if (data.success) {
      const sortedNotes = data.notes.sort((a, b) => new Date(b.LastModified) - new Date(a.LastModified));
      setNotes(sortedNotes);
      
      if (sortedNotes.length > 0) {
        // Find the index of the note with matching NoteIdentifier
        const indexToUse = sortedNotes.findIndex(note => note.NoteIdentifier === noteIdentifier);
        if (indexToUse !== -1) {
          setGuidingForceContent(sortedNotes[indexToUse].Note);
          setNoteIdentifier(sortedNotes[indexToUse].NoteIdentifier);
          setSelectedNoteIndex(indexToUse); // Set the selected index
        } else {
          console.log('Note with the given NoteIdentifier not found.');
        }
      } else {
        console.log('No AI Notes available for this chapter.');
        setGuidingForceContent('');
        setNoteIdentifier('');
        setSelectedNoteIndex(null); // Reset the selected index
      }
    } else {
      console.error('Error fetching AI notes:', data.message);
    }
  } catch (error) {
    console.error('Error fetching AI notes:', error.message);
  }
};




useEffect(() => {
  // Fetch initial data when the component mounts
  setTimeout(() => {
    fetchAndUpdateData();
  }, 50);
  

}, [WritingsIdentifier, selectedNoteIndex, updatePending]);

const handleNoteButtonClick = (note, noteIdentifier, index) => {
  //alert(note);
  setSelectedNoteIndex(index);
  setGuidingForceContent(note);
  setNoteIdentifier(noteIdentifier);
  setUpdatePending(false); // Reset the update pending flag

  // Save noteIdentifier to local storage
  localStorage.setItem('noteIdentifier', noteIdentifier);
  moveCursorToEnd();
};


const [activeMenu, setActiveMenu] = useState('notes');


  
let sortableLoaded = false; // Flag to check if Sortable has been loaded

const layoutModeToggle = async () => {
  const checkstoredbeat = localStorage.getItem('View');
  const storedBeatStyleName = localStorage.getItem('beatorbeatoverview');

  if (!checkstoredbeat) {
    localStorage.setItem('View', 'BeatBoard');
    setLayoutMode(true); // Assuming setLayoutMode is defined elsewhere
    await loadSortable(); // Attempt to load Sortable
  }   
  else if (checkstoredbeat === "BeatBoard") {
    localStorage.setItem('View', 'GuidedPen');
    setLayoutMode(true);

    const beatStyleExists = beatStyles.some(beatStyle => beatStyle.BeatStyle === storedBeatStyleName);
    if (storedBeatStyleName && beatStyleExists) {
      handleBeatStyleClick(storedBeatStyleName); // Assuming handleBeatStyleClick is defined elsewhere

      if (checkstoredbeat === "GuidedPen") {
        setLayoutMode(false);
        localStorage.setItem('View', 'BeatBoard');
      }
      await loadSortable(); // Attempt to load Sortable
    }
  }
  else if (checkstoredbeat === "GuidedPen") {
    setLayoutMode(false);
    localStorage.setItem('View', 'BeatBoard');
    await loadSortable(); // Attempt to load Sortable
  }
};

const loadSortable = async () => {
  if (!isSortableLoaded) {
    const SortableModule = await import('sortablejs/modular/sortable.complete.esm.js');
    sortableRef.current = SortableModule.default; // Store the Sortable module in the ref
    setIsSortableLoaded(true); // Indicate that Sortable has been loaded
  }
};



const saveChapterLayout = async () => {
  const orderingStr = localStorage.getItem('WritingsOrdering');
  if (!orderingStr) {
    alert('No chapter layout to save.');
    return;
  }

  alert('Saving chapter layout...');

  try {
    // Now, we make a single call to the server with the full ordering string.
    // The server will be responsible for parsing this string and updating each chapter accordingly.
    await updateBeatsGroupOnServer(orderingStr);
    alert('Chapter layout saved successfully!');
  } catch (error) {
    console.error('Failed to save chapter layout:', error);
    alert('Failed to save chapter layout. Please try again.');
  }

  // Optionally, you could reload the page or update the UI to reflect the changes.
   window.location.reload(); // Consider whether a reload is the best UX here.
};



useEffect(() => {
  const handleFocusAnywhere = (event) => {
    const target = event.target;

    setTimeout(() => {
      if (target && target.classList && target.classList.contains('tiptap')) {
        if (window.innerWidth < 1600) {
          setFullScreenMode(true);
        }

        if (fullscreenMode) {
          setHideGuidingForce(true);
          document.getElementById('guidingforce').style.setProperty('display', 'none', 'important');
        } else if (window.innerWidth < 970) {
          setHideGuidingForce(true);
          document.getElementById('outsidechapters').style.display = 'none';
          setSidebarToggle();
          document.getElementById('replaceLeft').style.display = 'none';
          document.getElementById('undo').style.display = 'none';
          document.getElementById('mainlogin').style.display = 'none';
        } else {
          setHideGuidingForce(false);
        }
      }
    }, 10);
  };

  const handleClickAnywhere = (event) => {
    console.log("Clicked element:", event.target.id);

    if (!fullscreenMode && event.target.id !== "guidingforce") {
      // If not in fullscreen mode, guidingforce should always be visible
      console.log("Not in fullscreen mode, guidingforce should always be visible.");
      return;
    }

    // Check if the toolbarmagicwand is clicked
    if (event.target.id === 'toolbarmagicwand') {
      console.log("toolbarmagicwand clicked");
      setHideGuidingForce((prevState) => {
        const newState = !prevState;
        console.log("Toggling hideGuidingForce to:", newState);
        document.getElementById('guidingforce').style.setProperty('display', newState ? 'none' : 'block', 'important');
        return newState;
      });
      return;
    }

    // Check for clicks that show guidingforce
    if (event.target.closest('.BubbleButton') || 
        event.target.closest('.chapterhighlight') || 
        event.target.id === 'replaceRight' || 
        event.target.id === 'fa-arrow-left') {

      console.log("Showing guidingforce");
      setHideGuidingForce(false);
      document.getElementById('guidingforce').style.setProperty('display', 'block', 'important');

      // Assuming you want to reset visibility of these elements when guidingforce is shown
      document.getElementById('replaceLeft').style.display = '';
      document.getElementById('replaceLeftmagic').style.display = '';
      document.getElementById('undo').style.display = '';
      document.getElementById('undo').style.visibility = 'visible';
    }
    // Check if the replaceLeft element is clicked to hide guidingforce
    else if (event.target.id === 'replaceLeft' || event.target.closest('#replaceLeft') || 
             event.target.id === 'editorouter' || 
             event.target.classList.contains('ProseMirror') || 
             event.target.classList.contains('ProseMirror-focused')) {

      console.log("Hiding guidingforce");
      document.getElementById('guidingforce').style.setProperty('display', 'none', 'important');
      setHideGuidingForce(true);

      // Hide 'replaceLeft', 'replaceLeftmagic', and 'undo' when guidingforce is hidden
      document.getElementById('replaceLeft').style.display = 'none';
      document.getElementById('replaceLeftmagic').style.display = 'none';
      document.getElementById('undo').style.display = 'none';
      document.getElementById('undo').style.visibility = 'hidden';
    } else {
      console.log("No matching condition");
    }
  };

  // Attach event listeners
  document.addEventListener('focus', handleFocusAnywhere, true); // Using capture phase
  document.addEventListener('click', handleClickAnywhere);

  // Cleanup function to remove event listeners upon component unmount
  return () => {
    document.removeEventListener('focus', handleFocusAnywhere, true);
    document.removeEventListener('click', handleClickAnywhere);
  };
}, [fullscreenMode]);



const zones = useRef([]);
const [currentTargetGroup, setCurrentTargetGroup] = useState('');
const groupsRefs = useRef([]);

const updateBeatsGroupOnServer = async (orderingStrValue) => {
  const TheWorksIdentifier = localStorage.getItem('WorksIdentifier');

  console.log("Request body:", {
    WorksIdentifier: TheWorksIdentifier,
    orderingStr: orderingStrValue,
    BeatSheetName: globalBeatSheetSelected // Assuming globalBeatSheetSelected is accessible here
  });

  try {
    const response = await fetch('https://guidedpen.com/API-GuidedPen/writingUpdateBeats', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        WorksIdentifier: TheWorksIdentifier,
        orderingStr: orderingStrValue,
        BeatSheetName: globalBeatSheetSelected
      }),
    });

    const data = await response.json();
    console.log('Server Response:', data);

    if (data.success) {
      // Save the updated ordering string in local storage
      localStorage.setItem('WritingsOrdering', orderingStrValue);
      
      // Call reorderChapterDetails to update the UI
      reorderChapterDetails();
    } else {
      console.error('Failed to update chapter layout on the server');
    }
  } catch (error) {
    console.error('Error updating beatsGroup on server:', error);
  }
};


const reorderChapterDetails = () => {
  const orderingStr = localStorage.getItem('WritingsOrdering');
  if (!orderingStr) return; // If there's no orderingStr, exit the function

  // Parse the orderingStr to create a mapping of beat groups to chapter identifiers
  const beatGroupOrder = orderingStr.split('\n').reduce((acc, line) => {
    const [group, ids] = line.split(': ');
    acc[group.trim()] = ids ? ids.split(',').map(id => id.trim()) : [];
    return acc;
  }, {});

  // Create a new ordered array based on the beatGroupOrder
  const orderedChapterDetailsArray = [];

  // Iterate through each beat in beatGroupOrder, including "Unmatched"
  Object.entries(beatGroupOrder).forEach(([group, ids]) => {
    ids.forEach(id => {
      const chapterDetail = chapterDetailsArray.find(chapter => chapter.WritingsIdentifier === id);
      if (chapterDetail) {
        orderedChapterDetailsArray.push(chapterDetail);
      }
    });
  });

  // Update chapterDetailsArray state with the newly ordered array
  setChapterDetailsArray(orderedChapterDetailsArray);

};






const handleDrop = async (evt) => {
  const targetBeatsGroup = evt.to.id;
  const writingsIdentifier = evt.item.dataset.identifier;

  // Logic to update chapterDetailsArray
  const updatedChapterDetailsArray = chapterDetailsArray.map(chapter => {
    if (chapter.WritingsIdentifier === writingsIdentifier) {
      return { ...chapter, beatsGroup: targetBeatsGroup };
    }
    return chapter;
  });


  // Assuming you have a setter for chapterDetailsArray if it's part of React state
  // setChapterDetailsArray(updatedChapterDetailsArray);
  // setChapters(updatedChapterDetailsArray);

  // Proceed with your existing logic...
  
  // Updating localStorage and server call
  let currentOrdering = {};
  const beatGroups = selectedBeatStyle?.IndividualBeats.map(beat => beat.BeatName) || [];
  beatGroups.push('Unmatched'); // Make sure to include 'Unmatched' in the beat groups

  beatGroups.forEach(groupName => {
    const container = document.getElementById(groupName);
    if (container) {
      const items = container.querySelectorAll('.sortable-item');
      currentOrdering[groupName] = Array.from(items).map(item => item.dataset.identifier);
    }
  });

  // Construct the orderingStr from the currentOrdering object
  const orderingStrValue = Object.entries(currentOrdering).map(([group, ids]) => {
    return `${group}: ${ids.join(', ')}`;
  }).join('\n');

  console.log('Updated Ordering String Value:', orderingStrValue);

  localStorage.setItem('WritingsOrdering', orderingStrValue);

  // Call the server update for the dragged chapter
 // await updateBeatsGroupOnServer(writingsIdentifier, targetBeatsGroup, orderingStrValue);

  localStorage.setItem('beatorbeatoverview', globalBeatSheetSelected)



  //This IF statement CHECKS if the current WritingsIdentifier is the one being dragged around, 
  //if it is, it sets selectedBeats, so the dropdown beatsheet shows their current one.
  //If the item being dragged is NOT the current writings Identifier, it ofcourse doesnt update it! :)
  if (WritingsIdentifier === writingsIdentifier) {
    setSelectedBeats(targetBeatsGroup);
}

return; //I return here, as everything else like updating the DB is now done with the save button

        // Optionally, refresh or update UI as needed
        setTimeout(() => {
          reorderChapterDetails();
        }, 1200);



};





const getItemCountInGroup = (beatsGroup) => {
  // This is a placeholder function. You need to replace it with actual logic
  // to count the items in a group. Initially, this could be based on your local
  // state that tracks the chapters and their groups.
  return chapterDetailsArray.filter(chapter => chapter.beatsGroup === beatsGroup).length;
};

const getOrderNumber = (beatsGroup) => {
  const match = beatsGroup.match(/\[(\d+)\]$/);
  return match ? parseInt(match[1], 10) : 0; // Return 0 if no order number is found
};



const alertAllGroupsOrdering = (chapterDataWritingsIdentifier, newBeatsGroup) => {
  const allGroupsOrdering = {};

  document.querySelectorAll('.sortable-list').forEach(group => {
    const groupId = group.id;
    const itemIdentifiers = Array.from(group.querySelectorAll('.sortable-item'))
                                 .map(item => item.dataset.identifier);
    
    allGroupsOrdering[groupId] = itemIdentifiers;
  });

  const orderingStrYea = Object.entries(allGroupsOrdering)
                            .map(([groupId, identifiers]) => `${groupId}: ${identifiers.join(', ')}`)
                            .join('\n');

  //alert(orderingStrYea); // Alert for debugging

  // Return the ordering string instead of setting state
  return orderingStrYea;
};









  // Function to group chapters by beatsGroup
const groupChaptersByBeatsGroup = (chapters) => {
  return chapters.reduce((acc, chapter) => {
    // If the group doesn't exist, initialize it with an empty array
    if (!acc[chapter.beatsGroup]) {
      acc[chapter.beatsGroup] = [];
    }
    // Push the current chapter into the group
    acc[chapter.beatsGroup].push(chapter);
    return acc;
  }, {});
};

// Grouped chapters for rendering
const groupedChapters = groupChaptersByBeatsGroup(chapterDetailsArray);

const handleDragStart = (e, chapter) => {
  console.log('DRAGGING STARTED')
  console.log('11111111111111111', e);
  console.log('11111111111111111', chapter);

  handleChapterClick(chapter.WritingsIdentifier);

  const dragData = {
    WritingsIdentifier: chapter.WritingsIdentifier,
    currentBeatsGroup: chapter.beatsGroup,
  };
  console.log("Setting drag data:", JSON.stringify(dragData));
  e.dataTransfer.setData("application/json", JSON.stringify(dragData));
  e.target.style.opacity = '0.5';
};


const handleDragOver = (e) => {
  e.preventDefault();
  // Optionally, add visual feedback here, e.g., highlight the drop zone
};


const handleDragEnd = (e) => {
  e.target.style.opacity = ''; // Reset visual feedback
};



//Pull BeatStyles
const [beatStyles, setBeatStyles] = useState([]);
const [selectedBeats, setSelectedBeats] = useState([]);
const [existingContent, setExistingContent] = useState([]);

useEffect(() => {
  fetch('https://guidedpen.com/API-GuidedPen/beatstyles', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
  })
  .then(response => response.json())
  .then(data => {
    const sortedData = data.sort((a, b) => Number(a.DisplayInThisOrder) - Number(b.DisplayInThisOrder));

    const parsedData = sortedData.map(item => {
      // Check if IndividualBeats is a string that needs parsing
      if (typeof item.IndividualBeats[0] === 'string') {
        try {
          // Assuming the first item is the stringified beats array
          item.IndividualBeats = JSON.parse(item.IndividualBeats[0].replace(/\\r\\n/g, '').trim());
        } catch (error) {
          console.error('Error parsing IndividualBeats JSON:', error);
          item.IndividualBeats = []; // Fallback to an empty array in case of error
        }
      }
      return item;
    });

    console.log('Fetched, Sorted, and Parsed BeatStyles:', parsedData);
    setBeatStyles(parsedData); // Assuming this is where you set your state
  })
  .catch(error => {
    console.error('Error fetching BeatStyles:', error);
  });
}, []);





const [selectedBeatStyle, setSelectedBeatStyle] = useState({ IndividualBeats: [] });


const handleBeatStyleClick = (beatStyleName) => {
  console.log('beatStyleName HERE', beatStyleName);

  setGlobalBeatSheetSelected(beatStyleName);
  const selectedStyle = beatStyles.find(style => style.BeatStyle === beatStyleName);
  console.log('selectedStyle selectedStyle selectedStyle', selectedStyle);

  if (selectedStyle) {
    setSelectedBeatStyle(selectedStyle); // Update state

    setBeatLayoutMode(true);
    document.getElementById('beatheader').innerHTML = beatStyleName;

  // setbeatorbeatoverviewmode(beatStyleName);
  //  localStorage.setItem('beatorbeatoverview', beatStyleName);

    determineNewOrderingForSelectedStyle(selectedStyle); // Pass the object, not the name

    // setTimeout(() => {
    //   reorderChapterDetails();
    // }, 1200);
  } else {
    console.error('Selected style not found for:', beatStyleName);
  }
};


// This function could be called on component mount or when you want to programmatically click the button
function programicallyClickhandleBeatStyleClick() {
  // Retrieve the stored beat style name
  const storedBeatStyleName = localStorage.getItem('beatorbeatoverview');
  
  if (storedBeatStyleName) {
    // Since IDs should be unique and your buttons might have different beat styles,
    // it's safer to use a data attribute or class name to select the button.
    // Assuming your buttons can be uniquely identified by their class names after replacements.
    const sanitizedBeatStyleName = storedBeatStyleName.replace(/\s+/g, '').replace(/'/g, '');
    const selector = `.beatbutton.${sanitizedBeatStyleName}`;

    // Find the button using the class name selector
    const button = document.querySelector(selector);

    if (button) {
      // Programmatically click the button
      button.click();
    } else {
      console.warn('No button found matching the stored beat style name:', storedBeatStyleName);
    }
  } else {
    console.warn('No stored beat style name found in localStorage');
  }
}




//Helps set the localStorage ON click between the beat Styles
// This example assumes you have a way to access the original beatsGroup for each chapter.
// If this information is not already part of your chapter data, you will need to add it.
const determineNewOrderingForSelectedStyle = (selectedStyle) => {
  console.log('Selected style:', selectedStyle.IndividualBeats);

 // alert('globalBeatSheetSelected is: ' + globalBeatSheetSelected);
  

  if (!selectedStyle || !selectedStyle.IndividualBeats || !selectedStyle.IndividualBeats.length) {
      console.error('Selected style or its IndividualBeats are undefined or empty', selectedStyle);
      return ''; 
  }

  const validBeats = selectedStyle.IndividualBeats.map(beat => beat.BeatName);

  console.log('RRR validBeats', validBeats)


  console.log('RRR chapterDetailsArray:', chapterDetailsArray);
  console.log('RRR globalBeatSheetSelected:', globalBeatSheetSelected);

  if (chapterDetailsArray.length > 0) {
    console.log('RRR First chapter structure:', chapterDetailsArray[0]);
  }


// Correct understanding based on the clarification
const relevantChapters = chapterDetailsArray.map(chapter => {
  if (validBeats.includes(chapter.beatsGroup)) {
      return chapter; // Keeps chapters as is if their beatsGroup matches validBeats
  } else {
      return { ...chapter, beatsGroup: "Unmatched" }; // Categorizes unmatched chapters
  }
});



  console.log('RRR relevantChapters', relevantChapters)


  // Then, map over relevantChapters to dynamically set displayGroup without altering original categorization
  const updatedChapterDetails = relevantChapters.map(chapter => {
    // Ensure each chapter has an originalBeatsGroup for reference
    if (!chapter.originalBeatsGroup) {
      chapter.originalBeatsGroup = chapter.beatsGroup;
    }
      
    // Dynamically set displayGroup for rendering based on current selected style
    chapter.displayGroup = validBeats.includes(chapter.originalBeatsGroup) ? chapter.originalBeatsGroup : "Unmatched";
    
    return chapter; // Return the chapter with updated displayGroup for dynamic UI rendering
  });
  
  // Only add "Unmatched" once to the list of beat names
  const beatNamesForOrdering = [...new Set([...validBeats, "Unmatched"])];
  



  // // Generate the new ordering string based on dynamic displayGroup
  // let orderingStr = "";
  // beatNamesForOrdering.forEach(beatName => {
  //     const ids = updatedChapterDetails
  //                   .filter(chapter => chapter.displayGroup === beatName)
  //                   .map(chapter => chapter.WritingsIdentifier)
  //                   .join(',');
  //     orderingStr += `${beatName}: ${ids}\n`;
  // });
  let orderingStr = localStorage.getItem('WritingsOrdering'); //I commented above out and instead just used getItem... seems to have fixed my issue





  const currentChapterID = WritingsIdentifier; // Example: Retrieving from localStorage

  // Find the chapter in updatedChapterDetails with this ID
  const selectedChapter = updatedChapterDetails.find(chapter => chapter.WritingsIdentifier === currentChapterID);

  if (selectedChapter && selectedBeatStyle) {
    const validBeats = selectedBeatStyle.IndividualBeats.map(beat => beat.BeatName);
    const isPartOfCurrentStyle = validBeats.includes(selectedChapter.displayGroup || selectedChapter.beatsGroup);
  
    // Alert based on whether the selected chapter is part of the current beat style
    if (isPartOfCurrentStyle) {
      //alert(`Chapter "${selectedChapter.chapterName}" is part of the current group: ${selectedChapter.displayGroup || selectedChapter.beatsGroup}`);
      setSelectedBeats(selectedChapter.displayGroup || selectedChapter.beatsGroup);
    } else {
      //alert(`Chapter "${selectedChapter.chapterName}" is marked as "Unmatched" in the current beat style.`);
    }
  } else {
    console.log('Selected chapter or beat style not found.');
  }



  console.log("New ordering string:", orderingStr);
  localStorage.setItem('WritingsOrdering', orderingStr);

  // Log for debugging
  console.log("Valid beats for style:", validBeats);
  console.log("Chapter details before update:", chapterDetailsArray);
  console.log("Chapter details after update:", updatedChapterDetails);

  // Update the UI based on the dynamically set displayGroup
  setChapterDetailsArray(updatedChapterDetails); // Assuming this function is responsible for updating the UI


  //   setTimeout(() => {
  //   reorderChapterDetails();
  // }, 1200);


  return orderingStr;
};















useEffect(() => {
  console.log("Current Selected Beat Style:", selectedBeatStyle);
  console.log("Individual Beat Names:", selectedBeatStyle?.IndividualBeats.map(beat => beat.BeatName));

  // Recalculate and update chapter categorizations based on the new selectedBeatStyle
  if (selectedBeatStyle) {
    const newOrderingStr = determineNewOrderingForSelectedStyle(selectedBeatStyle);
    console.log("New ordering string on beat style change:", newOrderingStr);
    // Assuming determineNewOrderingForSelectedStyle internally updates chapter categorizations and state as needed
  }
}, [selectedBeatStyle, selectedChapterIDtoggle]);


// UseEffect to log chapter beatsGroup values
useEffect(() => {
  console.log("Chapter beatsGroup Values:", chapterDetailsArray.map(chap => chap.beatsGroup));
}, [chapterDetailsArray]); // This useEffect depends on chapterDetailsArray


const handleJumpBackToBeat = () => {
  setBeatLayoutMode(false);
};




























  

//Helps set the color for the selected chapter
  useEffect(() => {
    if (saveWritingsIdentifier && saveWritingsIdentifier.length > 0) {
      // Get the first element of the array (assuming it contains only one element)
      const selectedWritingsIdentifier = saveWritingsIdentifier[0];
  
      // Set SelectedWritingsID with the correct WritingsIdentifier
      setSelectedWritingsID(selectedWritingsIdentifier);
    }
  }, [saveWritingsIdentifier]);

  






  const userLook = async (email, password) => {
    let auth = '';
  
    // If there's no password, try to get the auth token from local storage
    if (!password) {
      auth = localStorage.getItem('Auth');
    }
 
    try {
      const response = await fetch('https://guidedpen.com/API-GuidedPen/userLook', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, auth }),
      });
  
      const data = await response.json();
  
      if (data.success) {
        // Update application state with new data
        setWorks(data);
  
        // Check local storage for an existing WorksIdentifier
        const existingWorksIdentifier = localStorage.getItem('WorksIdentifier');
        const firstWorksIdentifier = existingWorksIdentifier || data.worksIdentifiers[0];
  
        setWorksIdentifier(firstWorksIdentifier);
        setMemberLevel(data.memberLevel);
  
        // Update local storage with the current WorksIdentifier
        localStorage.setItem('WorksIdentifier', firstWorksIdentifier);
  
        // Update local storage with new auth token and other relevant information
        setEmail(email);
        localStorage.setItem('Email', email); // Assuming you want to remember the user's email
        localStorage.setItem('Auth', data.auth); // Save the new auth token
        localStorage.setItem('UserID', data.UserID); // Save the UserID
  
        // Save the WritingsOrdering array to local storage
        localStorage.setItem('WritingsOrdering', JSON.stringify(data.WritingsOrdering));
  
        // Call workLook with the first WorksIdentifier
        workLook(firstWorksIdentifier, email); 
  
        setisLoggedIn(true);
        setUserImage(data.image);
        setName(data.Name);
        localStorage.setItem('User', data.Name);
  
        // Check if buttons is not null before splitting
        if (data.buttons) {
          setGlobalButtonsOrdering(data.buttons.split(','));
        } else {
          setGlobalButtonsOrdering([]); // Set to an empty array if buttons is null
        }
  
        return true;
      } else {
        console.error('Error pulling works:', data.message);
        throw new Error(data.message); // Reject the promise with an error message
      }
    } catch (error) {
      console.error('Error pulling works:', error.message);
      throw error; // Propagate the error to be caught in the calling function
    }
  };
  
  useEffect(() => {
    console.log('ooooooo userIsOwner updated:', userIsOwner);
    console.log('ooooooo ownerEmail updated:', ownerEmail);
  }, [userIsOwner, ownerEmail]);
  
  const workLook = async (worksIdentifier, email) => {
    try {
      const response = await fetch('https://guidedpen.com/API-GuidedPen/workLook', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ worksIdentifier, userEmail: email }),
      });
  
      const data = await response.json();
  
      if (data.success) {
        setAccessType('none');
  
        setDocumentData(data);
        setOwnerEmail(data.Owner);
  
        // Check if the user is the owner
        if (email === data.Owner) {
          setUserIsOwner(true);  // Set user as owner if matches
        } else {
          setUserIsOwner(false); // Explicitly set to false if not the owner
        }
  
        setWorksIdentifier(worksIdentifier);
        localStorage.setItem('WorksIdentifier', worksIdentifier);
  
        if (data.sharedWith) {
          let newAccessLevels = {};
          data.sharedWith.forEach(user => {
            newAccessLevels[user.email] = user.accessType;  // Populate the accessLevels state with actual access types
          });
          setAccessLevels(newAccessLevels);  // Update the state
        }
  
        if (data.WritingsOrdering) {
          localStorage.setItem('WritingsOrdering', JSON.stringify(data.WritingsOrdering));
  
          // Pass the entire WritingsOrdering array to writingLookup
          writingLookup(data.WritingsOrdering);
  
          // Handle BeatSheet if applicable
          const beatSheetPrefix = "BeatSheet: ";
          const firstWriting = data.WritingsOrdering[0];
          if (firstWriting && firstWriting.WritingsIdentifier.startsWith(beatSheetPrefix)) {
            const endOfBeatSheetNameIndex = firstWriting.WritingsIdentifier.indexOf("\n");
            if (endOfBeatSheetNameIndex !== -1) {
              const beatSheetName = firstWriting.WritingsIdentifier.substring(beatSheetPrefix.length, endOfBeatSheetNameIndex);
              console.log('BeatSheetName:', beatSheetName);
              setGlobalBeatSheetSelected(beatSheetName);  // Assuming this is your global variable for holding the BeatSheet name
            }
          }
  
        } else {
          console.log('WritingsOrdering is undefined');
        }
  
        if (data.WorksTitle) {
          document.getElementById('WorkTitleInput').innerText = data.WorksTitle;
          setWorksTitle(data.WorksTitle);
        }
  
      } else {
        console.error('Error pulling WritingsID:', data.message);
      }
    } catch (error) {
      console.error('Error pulling WritingsID:', error.message);
    }
  };
  
  
  
  const writingLookup = async (WritingsIdentifierList) => {
    if (!WritingsIdentifierList || WritingsIdentifierList.length === 0) {
      console.error('Invalid WritingsIdentifierList:', WritingsIdentifierList);
      return;
    }
  
    const storedWritingsIdentifier = localStorage.getItem('writingsIdentifier');
    const selectedIdentifier = storedWritingsIdentifier && WritingsIdentifierList.some(item => item.WritingsIdentifier === storedWritingsIdentifier)
      ? storedWritingsIdentifier
      : WritingsIdentifierList[0].WritingsIdentifier;
  
    localStorage.setItem('writingsIdentifier', selectedIdentifier);
  
    if (provider) {
      provider.disconnect();
      provider.destroy();
    }
    if (ydocRef.current) {
      ydocRef.current.destroy();
    }
  
   // editor.commands.clearContent();
  
    ydocRef.current = new Y.Doc();
  
    try {
      const response = await fetch('https://guidedpen.com/API-GuidedPen/writingLookup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          WritingsIdentifier: WritingsIdentifierList.map(item => item.WritingsIdentifier).join(','),
          selectedWritingsIdentifier: selectedIdentifier
        }),
      });
  
      const data = await response.json();
      console.log('Data:', data);
  
      if (data.success && data.chapterDetails.length > 0) {
        // Log the chapter writing identifiers before processing
        console.log('Original order of chapters:', data.chapterDetails.map(chapter => chapter.WritingsIdentifier));
  
        const { sortedChapterDetails, selectedChapter } = reorderAfterWritingLookup(data.chapterDetails, selectedIdentifier);
  
        // Log the chapter writing identifiers after processing
        console.log('Processed order of chapters:', sortedChapterDetails.map(chapter => chapter.WritingsIdentifier));
  
        setChapterName(selectedChapter.chapterName);
        setchapterContent(selectedChapter.chapterContent);
        setChapterBody(selectedChapter.chapterContent);
        setWritingsIdentifier(selectedChapter.WritingsIdentifier);
        setWritingsID(selectedChapter.WritingsIdentifier);
        setChapterDetailsArray(sortedChapterDetails);
        setChapters(sortedChapterDetails);
        setSelectedBeats(selectedChapter.beatsGroup);
  
        if (selectedChapter.chapterContent.length > 200) {
          document.getElementById('h1').style.display = 'none';
        }
  
        const newProvider = new WebsocketProvider(serverUrl, selectedChapter.WritingsIdentifier, ydocRef.current);
        setProvider(newProvider);
  
        setTimeout(() => {
          const isYjsDocumentEmpty = ydocRef.current.getText('prosemirror').length === 0;
          if (isYjsDocumentEmpty) {
            console.log('Got to here1', selectedChapter.chapterContent)

              console.log('Got to here2', selectedChapter.chapterContent)
             // editor.chain().setContent('TEST').run();
             setChapterContentToSet(selectedChapter.chapterContent) //this needed cause editor not set yes... this is on page load
             // editor.commands.setContent(selectedChapter.chapterContent);
              console.log('Got to here2', selectedChapter.chapterContent)
            } else {
              console.error('Editor is not available.');
          }
        }, 1000); // Delay to check if peer has loaded content or not
  
        setSelectedWritingsID(selectedChapter.WritingsIdentifier);
  
        setTimeout(() => {
          programicallyClickhandleBeatStyleClick();
        }, 3000);
  
      } else if (!data.success && data.message === "No entries found with the provided WritingsIdentifier(s)") {
        const newWritingsIdentifier = uuid();
        setWritingsIdentifier(newWritingsIdentifier);
        setWritingsID(newWritingsIdentifier);
        setSelectedWritingsID(newWritingsIdentifier);
        setChapterName('');
        setchapterContent('');
        setChapterBody('');
        setChapterDetailsArray([
          {
            chapterName: 'Sample Chapter',
            WritingsIdentifier: newWritingsIdentifier,
            chapterSummary: 'This is a sample chapter to inspire your writing journey.',
            beatsGroup: 'Introduction',
            chapterContent: '<h1>Sample Chapter...</h1><p>It was the best of times, it was the worst of times...</p>'
          }
        ]);
        const { sortedChapterDetails } = reorderAfterWritingLookup(chapterDetailsArray, newWritingsIdentifier);
        setChapters(sortedChapterDetails);
        console.log('No entries found, setting new WritingsIdentifier:', newWritingsIdentifier);
  
      } else {
        console.error('Error pulling chapters:', data.message);
      }
    } catch (error) {
      console.error('Error during writing lookup:', error);
    }
  };
  
  
  
  
  const writingLookupFromClick = async (chapterwritingID) => {
    localStorage.setItem('writingsIdentifier', chapterwritingID);
  
    if (provider) {
      provider.disconnect();
      provider.destroy();
    }
    if (ydocRef.current) {
      ydocRef.current.destroy();
    }
  
    editor.commands.clearContent();
  
    ydocRef.current = new Y.Doc();
  
    try {
      const response = await fetch('https://guidedpen.com/API-GuidedPen/writingLookup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ WritingsIdentifier: chapterwritingID }),
      });
      const data = await response.json();
  
      if (data.success && data.chapterDetails) {
        // Log the chapter writing identifiers before processing
        console.log('Original order of chapters:', data.chapterDetails.map(chapter => chapter.WritingsIdentifier));
  
        const { sortedChapterDetails, selectedChapter } = reorderAfterWritingLookup(data.chapterDetails, chapterwritingID);
  
        // Log the chapter writing identifiers after processing
        console.log('Processed order of chapters:', sortedChapterDetails.map(chapter => chapter.WritingsIdentifier));
  
        const newProvider = new WebsocketProvider(serverUrl, chapterwritingID, ydocRef.current);
        setProvider(newProvider);
  
        setTimeout(() => {
          const isYjsDocumentEmpty = ydocRef.current.getText('prosemirror').length === 0;
          if (isYjsDocumentEmpty) {
            editor.commands.setContent(selectedChapter.chapterContent);
          }
        }, 1000); // Delay to check if peer has loaded content or not
  
        setSelectedWritingsID(selectedChapter.WritingsIdentifier);
      } else {
        console.error('Error looking up chapter details:', data.message);
      }
    } catch (error) {
      console.error('Error looking up chapter details:', error.message);
    }
  };
  
  
  
  
  
    
  const reorderAfterWritingLookup = (chapterDetails, writingIdentifier) => {
    const orderingStr = localStorage.getItem('WritingsOrdering');
    if (orderingStr) {
      const orderArray = JSON.parse(orderingStr);
      const orderMap = orderArray.reduce((acc, { WritingsIdentifier }, index) => {
        acc[WritingsIdentifier] = index;
        return acc;
      }, {});
  
      // Sort the chapters based on the order from local storage
      chapterDetails.sort((a, b) => {
        return orderMap[a.WritingsIdentifier] - orderMap[b.WritingsIdentifier];
      });
  
      // Log the expected and actual orders for debugging
      console.log('Expected order from local storage:', orderArray.map(item => item.WritingsIdentifier));
      console.log('Actual order after sorting:', chapterDetails.map(chapter => chapter.WritingsIdentifier));
    }
  
    const selectedChapter = chapterDetails.find(chapter => chapter.WritingsIdentifier === writingIdentifier) || chapterDetails[0];
    return { sortedChapterDetails: chapterDetails, selectedChapter };
  };
  
  
  
  
  



const handleDeleteWriting = async (writingsID) => {
  // Prevent deletion if only one chapter exists
  if (chapterDetailsArray.length <= 1) {
    alert("You can't delete the last chapter. You must have at least one chapter.");
    return; // Exit the function early
  }

  const isConfirmed = window.confirm('Are you sure you want to delete your chapter?');

  if (isConfirmed) {
    try {
      const response = await fetch('https://guidedpen.com/API-GuidedPen/writingsDelete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ writingsID }), // Removed worksIdentifier
      });

      const data = await response.json();

      if (data.success) {
        console.log('Writing deleted successfully');

        // Update the chapterDetailsArray state
        const updatedChapters = chapterDetailsArray.filter(chapter => chapter.WritingsIdentifier !== writingsID);
        setChapterDetailsArray(updatedChapters);
        setChapters(updatedChapters);

        // Update selection if the deleted chapter is the currently selected one
        if (writingsID === selectedWritingsID) {
          let newSelectedChapter = null;

          if (updatedChapters.length > 0) {
            // If there are still chapters left, select the next one in the list, or the previous if the deleted was the last
            const deletedIndex = chapterDetailsArray.findIndex(chapter => chapter.WritingsIdentifier === writingsID);
            newSelectedChapter = deletedIndex < updatedChapters.length ? updatedChapters[deletedIndex] : updatedChapters[deletedIndex - 1];
          }

          console.log('New Selected Chapter:', newSelectedChapter);

          setWritingsIdentifier(newSelectedChapter ? newSelectedChapter.WritingsIdentifier : "");
          setSelectedWritingsID(newSelectedChapter ? newSelectedChapter.WritingsIdentifier : "");
          setChapterBody(newSelectedChapter ? newSelectedChapter.chapterContent : "");

          if (newSelectedChapter) {
            handleChapterClick(newSelectedChapter.WritingsIdentifier, true);
          }
        }
      } else {
        console.error('Error deleting writing:', data.message);
      }
    } catch (error) {
      console.error('Error deleting writing:', error);
    }
  } else {
    console.log('Deletion canceled');
  }
};











  // This function can now be called directly with the latest input values
  const handleLogin = () => {
    userLook(email, password);
  };


  const handleChapterClick = (chapterwritingID, event) => {

    if (event && event.target.classList.contains('DeleteButton')) {
      // Do nothing if the click is from the delete button
      return;
    }

      //saveChapterNameToDatabase(); // AUTO SAVE IF THEY TRY CHANGING CHAPTERS THEY ARE VIEWING

  
    // Optional: Clear editor content immediately to avoid showing stale content
    setTimeout(() => { //delayed setting chapterId, this is used for collab, when it sets, other stuff fires.
      // Update the chapter ID which triggers the useEffect to create a new provider
      // Fetch new chapter details; consider doing this inside useEffect after provider is ready
      writingLookupFromClick(chapterwritingID);
    }, 1000);

  


  

  
    // Update UI states
    setSelectedChapterIDtoggle(chapterwritingID);
    setWritingsID(chapterwritingID);
    setWritingsIdentifier(chapterwritingID);
    

  
    if (window.innerWidth < 970) {
      setSidebarToggle(false);
    }
  };










// Save new Chapter
useEffect(() => {
  const handleKeyPress = async (event) => {
    if (event.key === 'Enter' && newChapterInput.trim() !== '') {
      // Generate a new UUID for the WritingsIdentifier
      const newchapteruuid = uuid();
      localStorage.setItem('writingsIdentifier', newchapteruuid);

      // Send a POST request to save the chapter
      const chapterResponse = await fetch('https://guidedpen.com/API-GuidedPen/saveChapter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chapterName: newChapterInput,
          chapterContent: '<h1>' + newChapterInput + '</h1><p></p>',
          WritingsIdentifier: newchapteruuid,
          beatsGroup: 'Unmatched',
          WorksIdentifier: worksIdentifier // Pass the WorksIdentifier here
        }),
      });

      const chapterDataChapter = await chapterResponse.json();

      if (chapterDataChapter.success) {
        console.log('chapterData', chapterDataChapter.data);
        //alert('Body: ' + chapterDataChapter.data.chapterContent);
        setChapterBody(chapterDataChapter.data.chapterContent);
        setWritingsIdentifier(chapterDataChapter.data.WritingsIdentifier);

        // Optionally, select the new chapter
        //  selectedChapter({
        //    chapterName: chapterDataSChapter.data.chapterName,
        //    chapterContent: chapterDataSChapter.data.chapterContent,
        //    WritingsIdentifier: chapterDataSChapter.data.WritingsIdentifier,
        //  });

        setChapterDetailsArray((prevChapters) => [
          ...prevChapters,
          {
            chapterName: chapterDataChapter.data.chapterName,
            chapterContent: chapterDataChapter.data.chapterContent,
            WritingsIdentifier: chapterDataChapter.data.WritingsIdentifier,
            beatsGroup: chapterDataChapter.data.beatsGroup,
          },
        ]);

        setSelectedWritingsID(chapterDataChapter.data.WritingsIdentifier);

        setChapters((prevChapters) => [
          ...prevChapters,
          {
            chapterName: chapterDataChapter.data.chapterName,
            chapterContent: chapterDataChapter.data.chapterContent,
            WritingsIdentifier: chapterDataChapter.data.WritingsIdentifier,
            beatsGroup: chapterDataChapter.data.beatsGroup,
          },
        ]);

        // Clear the input field
        setNewChapterInput('');
      } else {
        console.error('Error saving new chapter:');
      }
    }
  };

  // Add event listener when the component mounts
  document.addEventListener('keydown', handleKeyPress);

  // Clean up the event listener when the component unmounts
  return () => {
    document.removeEventListener('keydown', handleKeyPress);
  };
}, [newChapterInput, selectedChapter]);



//This autofires to toggle the chapters
useEffect(() => {

  setChapterDetailsArray(chapterDetailsArray)

}, [chapterDetailsArray]); // Dependency array



  // Function to toggle the modal's visibility
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [isStripeInitialized, setIsStripeInitialized] = useState(false);

  useEffect(() => {
    if (isModalOpen && !isStripeInitialized) {
      import('@stripe/stripe-js').then(({ loadStripe }) => {
        setStripePromise(loadStripe('pk_test_tL2ePrhcmqIerRlH1t8KjhsM'));
      });
      import('@stripe/react-stripe-js').then((module) => {
        setElements(() => module.Elements);
      });
      setIsStripeInitialized(true); // Mark Stripe as initialized
    }
  }, [isModalOpen, isStripeInitialized]);

  // Modal component defined inline
  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
      <div
        className="modal-overlay"
        style={{ position: 'fixed', zIndex: 99999, top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)' }}
        onClick={onClose}
      >
        <div className="modal-content" onClick={e => e.stopPropagation()} style={{ position: 'relative', zIndex: 100000 }}>
          <button className="modal-close-button" onClick={onClose}>X</button>
          {children}
        </div>
      </div>
    );
  };



// function handleWorkTitleBlur(event) {
//   const updatedWorksTitle = event.target.value;

//   // Ensure editingWork is available and correctly managed in the component's state
//   if (!editingWork || !editingWork.WorksIdentifier) {
//     console.error('No work is currently being edited or identifier is missing');
//     return;
//   }

//   // Construct the request payload with the updated title and identifier
//   const payload = {
//     WorksIdentifier: editingWork.WorksIdentifier, // Use the identifier from the state
//     WorksTitle: updatedWorksTitle,
//   };

//   // Use fetch to send the POST request
//   fetch('https://guidedpen.com/API-GuidedPen/updateWorkTitle', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(payload),
//   })
//   .then(response => response.json())
//   .then(data => {
//     console.log('Success:', data);
//     alert('Title updated successfully!');
//   })
//   .catch((error) => {
//     console.error('Error:', error);
//     alert('Failed to update title.');
//   });
// }


// Toggle DarkMode
const toggleDarkMode = () => {
  // Retrieve the current mode or default to 'normal'
  const currentMode = localStorage.getItem('darkMode') || 'normal';

  let newMode;
  switch (currentMode) {
    case 'normal':
      newMode = 'dark';
      break;
    case 'dark':
      newMode = 'hacker';
      break;
    case 'hacker':
      newMode = 'normal';
      break;
    default:
      newMode = 'normal';
  }

  // Update the mode in both React state and localStorage
  setDarkMode(newMode); // Assuming setDarkMode updates your React state for the mode
  localStorage.setItem('darkMode', newMode);

  // Dispatch a custom event to notify parts of your application about the mode change
  window.dispatchEvent(new Event('darkModeChange'));
};

//Handle color mode on page load... the mutation awaits for prose editor to be available.
useEffect(() => {
  const handleDarkModeChange = () => {
    const currentMode = localStorage.getItem('darkMode') || 'normal';
    const editorElement = document.querySelector('.ProseMirror');
   
    if (editorElement) {
      editorElement.classList.remove('dark', 'hacker');
      if (currentMode !== 'normal') {
        editorElement.classList.add(currentMode);
      }
    }
  };

  const observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (mutation.addedNodes.length) {
        handleDarkModeChange(); // Try applying the mode whenever nodes are added
      }
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });

  window.addEventListener('darkModeChange', handleDarkModeChange);
  
  handleDarkModeChange(); // Also apply at initial load

  return () => {
    window.removeEventListener('darkModeChange', handleDarkModeChange);
    observer.disconnect(); // Don't forget to disconnect the observer to prevent memory leaks
  };
}, []);



//Handle adding fullscreenmode class if it is selected
useEffect(() => {
  // Function to apply or remove the FullScreenMode class
  const applyFullscreenMode = () => {
    const editorElement = document.querySelector('.ProseMirror');
    if (editorElement) {
      if (fullscreenMode) {
          editorElement.classList.add('FullScreenMode');
      //    document.getElementById('guidingforce').style.display = 'none';
      } else {
          editorElement.classList.remove('FullScreenMode');
          if (window.innerWidth > 970) {
            document.getElementById('guidingforce').style.display = 'block';
          }
      }
    }
  };

  // Custom event listener for toggling fullscreen mode from anywhere in the app
  const toggleFullscreenMode = () => {
    const currentMode = !fullscreenMode;
    setFullScreenMode(currentMode);
    localStorage.setItem('FullScreen', currentMode.toString());
  };

  window.addEventListener('toggleFullscreenMode', toggleFullscreenMode);

  // MutationObserver to detect when the ProseMirror editor is added to the DOM
  const observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (mutation.addedNodes.length) {
        applyFullscreenMode();
      }
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });

  // Apply fullscreen mode on initial load or when fullscreenMode state changes
  applyFullscreenMode();

  return () => {
    window.removeEventListener('toggleFullscreenMode', toggleFullscreenMode);
    observer.disconnect();
  };
}, [fullscreenMode]);



const toggleModalProfile = () => {
  if (!isModalProfileOpen) {
    fetchWorkDetails(); // Fetch work details only when opening the modal
  }
  setIsModalProfileOpen(!isModalProfileOpen);
};

// Constants moved outside the component
const fileInputRef = useRef(null);
const [editingWork, setEditingWork] = useState(null);
const [worksDetails, setWorksDetails] = useState([]);

  function ModalProfile({ isOpen, onClose, worksDetails }) {
    if (!isOpen) return null;
  
    const handleWorkClick = (worksIdentifier) => {
      // Clear the editor content
      editor.commands.clearContent();
    
      // Now run the workLook function
      workLook(worksIdentifier, email);
    
      // Close the cover
      document.getElementById('closecover').click();
    };
    
  
    const handleEditClick = (e, work) => {
      e.stopPropagation();
      setEditingWork({...work});
    };
  
    const handleSaveChanges = async () => {
      if (!editingWork) return;
  
      const inputElement = document.getElementById('workTitleInput');
      const updatedWorksTitle = inputElement.value;
  
      const payload = {
        WorksIdentifier: editingWork.WorksIdentifier,
        WorksTitle: updatedWorksTitle,
      };
  
      await fetch('https://guidedpen.com/API-GuidedPen/updateWorkTitle', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
      .then(response => response.json())
      .then(data => {
        alert('Changes saved successfully!');
        fetchWorkDetails(); // Optionally repull the works details
        setEditingWork(null); // Optionally close the edit box
        workLook(worksIdentifier, email); //Re-fire this so the title in the sidebar shows the update
        onClose(); // Optionally close the edit form
      })
      .catch(error => {
        console.error('Error updating title:', error);
        alert('Failed to update title.');
      });
    };
  
    const handleFileChange = (e) => {
      e.stopPropagation(); // Prevent any propagation
      const file = e.target.files[0];
      if (file) {
        handleFileUpload(file); // Upload immediately after selecting the file
      }
    };
    
    const handleFileUpload = async (file) => {
      const formData = new FormData();
      formData.append('coverImage', file); // Add the image file
      formData.append('worksIdentifier', editingWork.WorksIdentifier); // Add the identifier
    
      try {
        const response = await fetch('https://guidedpen.com/API-GuidedPen/upload-cover', {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) {
          throw new Error('Failed to upload image');
        }
        const data = await response.json();
        alert("Image uploaded successfully!"); // Notify the user
        setEditingWork(prev => ({ ...prev, Cover: data.imageUrl })); // Update the cover in state
    
        // Optional: Refresh details or close modal
        fetchWorkDetails(); // Repull the works details if necessary
        setEditingWork(null); // Optionally close the edit form
      } catch (error) {
        console.error('Error uploading image:', error);
        alert("Failed to upload image. Please try again."); // Alert the user on failure
      }
    };
    
    const handleClick = (e) => {
      e.stopPropagation(); // Ensure this is here to stop propagation
      fileInputRef.current.click(); // Trigger the file input
    };
    
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content modalcontentprofile" onClick={e => e.stopPropagation()}>
          <h2 className='bookshelfheader'>My Bookshelf</h2>
          {worksDetails.map((work, index) => (
              <div key={index} className='cover' onClick={() => handleWorkClick(work.WorksIdentifier)}
                  style={{ backgroundImage: `url(${work.Cover ? `https://guidedpen.com/${work.Cover}` : 'https://guidedpen.com/coverimg/default-cover-url.jpg'})`, backgroundSize: 'cover', margin: '10px' }}>
                <button className="editButton" onClick={(e) => handleEditClick(e, work)}>
                  <i className="fa fa-pencil-alt"></i>
                </button>
                {work.Cover === null && (
                  <p className='worksTitleHeader'>{work.WorksTitle}</p>
                )}
              </div>
            ))}
            <br></br>
            <button onClick={addBook} className='addBook'>Add Book</button>
            <button onClick={onClose} className="closeButton" hidden id='closecover'>Close</button>
            </div>
            {editingWork && (
              <div className="editForm" onClick={(e) => e.stopPropagation()}>
                <button className='editclose' onClick={() => setEditingWork(null)}>x</button>
                <input
                  type="text"
                  id="workTitleInput"
                  defaultValue={editingWork.WorksTitle}
                  className='bookeditinput'
                  autoFocus // Add autofocus to ensure the input gets focus
                  disabled={editingWork.OwnershipType === 'shared'} // Disable input if the work is shared
                />
                <div
                  className={`drop-zone ${editingWork.OwnershipType === 'shared' ? 'disabled' : ''}`}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={editingWork.OwnershipType === 'shared' ? null : handleDrop}
                  onClick={editingWork.OwnershipType === 'shared' ? null : handleClick}
                  style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}
                >
                  Drag your book cover image here <br></br>or click to select
                  <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} disabled={editingWork.OwnershipType === 'shared'} />
                </div>
                {editingWork.OwnershipType === 'shared' && (
                  <p className='sharedbookmsg'>
                    This is shared, only the creator can alter here.
                  </p>
                )}
                <button onClick={handleSaveChanges} className='booksaveinput' disabled={editingWork.OwnershipType === 'shared'}>
                  Save Changes
                </button>
              </div>
            )}

      </div>
    );
    }
    


    const fetchWorkDetails = async () => {
      const userID = localStorage.getItem('UserID'); // Retrieve the UserID from local storage
    
      try {
        const response = await fetch('https://guidedpen.com/API-GuidedPen/worksGrab', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userID }), // Pass the UserID in the request body
        });
    
        const data = await response.json();
    
        if (data.success) {
          // Assuming you have a state or method to handle the fetched work details
          // Update state with the fetched work details
          setWorksDetails(data.works); // Assuming data.works is an array
          console.log('Fetched Work Details:', data.works);
        } else {
          console.error('Failed to fetch work details:', data.message);
        }
      } catch (error) {
        console.error('Error fetching work details:', error);
      }
    };


const printDocument = () => {
  if (!editor) {
    console.error("Editor not initialized.");
    return;
  }
  const editorContent = editor.getHTML();

  // Create an invisible iframe
  let printIframe = document.createElement('iframe');
  printIframe.style.visibility = 'hidden';
  printIframe.style.position = 'absolute';
  printIframe.style.width = '0px';
  printIframe.style.height = '0px';
  document.body.appendChild(printIframe);

  let doc = printIframe.contentDocument || printIframe.contentWindow.document;
  doc.open();
  doc.write(`
    <html>
        <head>
            <title>Print</title>
            <style>
            @media print {
              body {
                margin: 20;
                padding: 30;
              }
              .print-content {
                width: 100%;
                box-sizing: border-box;
                page-break-after: always;
              }
            }
            </style>
        </head>
        <body>
            ${editorContent}
        </body>
    </html>`);
  doc.close();

  // Use a timeout to ensure content is fully loaded
  setTimeout(() => {
    try {
      printIframe.contentWindow.focus();
      printIframe.contentWindow.print();
    } catch (error) {
      console.error("Error during print:", error);
    }
    
    // Ensure the iframe is removed after printing
    setTimeout(() => {
      if (document.body.contains(printIframe)) {
        document.body.removeChild(printIframe);
      }
    }, 1000); // Adjust timing as needed based on printer dialog
  }, 500); // Adjust delay for content loading if needed
};


const getIconSrc = (mode) => {
  switch (mode) {
    case 'dark':
      return 'https://guidedpen.com/icon-dark.png';
    case 'hacker':
      return 'https://guidedpen.com/icon-hacker.png';
    default:
      return 'https://guidedpen.com/icon.png';
  }
};



const [buttons, setButtons] = useState([]);
const [newButtonText, setNewButtonText] = useState('');
const [newButtonCommand, setNewButtonCommand] = useState('');
const [error, setError] = useState('');

const fetchButtons = async () => {
  try {
    const response = await fetch('https://guidedpen.com/API-GuidedPen/buttonsGrab');
    if (response.ok) {
      const data = await response.json();
      const fetchedButtons = data.buttons;

      // Check if globalButtonsOrdering is empty. If it is, skip the ordering logic.
      if (globalButtonsOrdering.length === 0) {
        setButtons(fetchedButtons);
      } else {
        // First, create the ordered buttons based on globalOrder
        const orderedButtons = globalButtonsOrdering.map(id => 
          fetchedButtons.find(button => button.ButtonID === id)
        ).filter(button => button !== undefined); // Ensure we exclude any undefined entries

        // Then, identify buttons not included in globalButtonsOrdering
        const unorderedButtons = fetchedButtons.filter(button => 
          !globalButtonsOrdering.includes(button.ButtonID)
        );

        // Combine the two arrays to set the buttons state
        setButtons([...orderedButtons, ...unorderedButtons]);
      }
    } else {
      const errorData = await response.json();
      console.error(errorData.message || 'An error occurred while fetching buttons');
    }
  } catch (error) {
    console.error('Failed to fetch buttons due to a network error');
  }
};


// Use fetchButtons inside useEffect
useEffect(() => {
  fetchButtons();
}, [globalButtonsOrdering]);


if (error) {
  return <div>Error: {error}</div>;
}


function moveItem(direction, index) {
  setButtons((currentButtons) => {
    let newButtons = [...currentButtons];
    // Moving up
    if (direction === "up" && index > 0) {
      [newButtons[index - 1], newButtons[index]] = [newButtons[index], newButtons[index - 1]];
    }
    // Moving down
    else if (direction === "down" && index < newButtons.length - 1) {
      [newButtons[index], newButtons[index + 1]] = [newButtons[index + 1], newButtons[index]];
    }
    saveButtonOrder();
    return newButtons;
  });
}

const saveButtonOrder = async () => {
  const storedEmail = localStorage.getItem('Email');

  const buttonOrder = buttons.map(button => button.ButtonID).join(',');
  // Assuming you have the current user's ID available

  try {
    const response = await fetch('https://guidedpen.com/API-GuidedPen/saveButtonOrder', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ storedEmail, buttonOrder }),
    });

    if (response.ok) {
      console.log("Order saved successfully.");
    } else {
      console.error("Failed to save order.");
    }
  } catch (error) {
    console.error("Error saving order:", error);
  }
};


const handleAddButton = async () => {
  if (!newButtonText.trim() || !newButtonCommand.trim()) return; // Basic validation

  try {
    const response = await fetch('https://guidedpen.com/API-GuidedPen/addButton', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        buttonText: newButtonText,
        buttonCommand: newButtonCommand, // Include the command in the request
        name: name
      }),
    });

    const data = await response.json();
    if (data.success) {
      fetchButtons(); // Refetch buttons to include the new addition
      setNewButtonText(''); // Reset text field
      setNewButtonCommand(''); // Reset command field
    } else {
      console.error('Error adding new button:', data.message);
    }
  } catch (error) {
    console.error('Error adding new button:', error);
  }
};




const toggleButtonModal = () => setIsModalButtonOpen(!isModalButtonOpen);

function ButtonOrderModal({ isButtonOpen, onButtonClose, buttons, setButtons, moveItem, saveButtonOrder, newButtonText, setNewButtonText, newButtonCommand, setNewButtonCommand, handleAddButton }) {
  if (!isButtonOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="modal modalbutton" onClick={onButtonClose}>
      <div className="modal-content modal-contentbutton" onClick={e => e.stopPropagation()}> {/* Prevent modal from closing when clicking inside */}
        <div className="button-order">
          <div>
            <div className='toolboxsubheadline'><h3>Writers Toolbox</h3>
            The AI Buttons you create here are shared publicly. We are a community of writers, and this helps us all. Of course, if you'd like to do a private AI command, simply type it in manually.
            </div>
            {buttons.map((button, index) => (
              <div key={button.ButtonID} className="button-item">
                <div><b>{button.ButtonText}:</b> {button.ButtonCommand}</div>
                <button onClick={() => moveItem("up", index)} className='buttonarrows'><i class="fa-solid fa-arrow-up"></i></button>
                <button onClick={() => moveItem("down", index)} className='buttonarrows'><i class="fa-solid fa-arrow-down"></i></button>
              </div>
            ))}
          </div>
          <button hidden nClick={saveButtonOrder}>Save</button>
          
          <div className='newbuttoncontainer'>
            <input
            className='newbuttontext'
              type="text"
              value={newButtonText}
              onChange={(e) => setNewButtonText(e.target.value)}
              placeholder="Create button with text"
            />
            <input
            className='newbuttoncommand'
              type="text"
              value={newButtonCommand}
              onChange={(e) => setNewButtonCommand(e.target.value)}
              placeholder="And the command..."
            />
            <button className='newbuttonsave' onClick={handleAddButton}><i class="fa-solid fa-plus"></i></button>
          </div>
        </div>
        <button onClick={onButtonClose} hidden>Close</button> {/* Close button */}
      </div>
    </div>
  );
}


async function addBook() { 
  // Check if member is not 'Paid'
  if (memberLevel !== 'PAID') {
    alert('You must be a paid member to add new books.');
    document.getElementById('closecover').click();
    toggleModal(); // Opens the payment modal for membership upgrade
    return; // Stop further execution if not a paid member
  }

  const email = localStorage.getItem('Email');
  const auth = localStorage.getItem('Auth');
  const newWorksIdentifier = uuid(); // Generate a new UUID for the new work
  const newWorksTitle = 'New Book Title'; // Example title, or retrieve this from user input
  const newwritingID = uuid();

  try {
    const response = await fetch('https://guidedpen.com/API-GuidedPen/AddBook', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        auth,
        newWorksIdentifier,
        newWorksTitle, // This is the title being passed
      }),
    });

    const data = await response.json();



    if (data.success) {



      setWritingsIdentifier(newwritingID);



          alert("Book added successfully!");
          setWorksIdentifier(newWorksIdentifier); // Sets the new work added as the current identifier

          // Reset or initialize state related to chapters and the work
          setChapterDetailsArray([{
            chapterName: data.newWorksTitle, // Use the title from the response
            WritingsIdentifier: WritingsIdentifier,
            chapterSummary: '',
            beatsGroup: '',
            chapterContent: `<h1>${data.newWorksTitle}...</h1><p></p>` // Correctly use template literals
          }]);

          setChapters([{
            chapterName: data.newWorksTitle, // Use the title from the response
            WritingsIdentifier: WritingsIdentifier,
            chapterSummary: '',
            beatsGroup: '',
            chapterContent: `<h1>${data.newWorksTitle}...</h1><p></p>` // Correctly use template literals
          }]);

          setchapterContent(`<h1>${data.newWorksTitle}...</h1><p></p>`); // Correctly use template literals
          editor.commands.setContent(`<h1>${data.newWorksTitle}...</h1><p></p>`); // Correctly use template literals
          document.getElementById('WorkTitleInput').innerText = data.newWorksTitle; // Use the title from the response
          setWorksTitle(data.newWorksTitle); // Use the title from the response
          setChapterName(data.newWorksTitle); // Use the title from the response

          setTimeout(() => {
          localStorage.setItem('writingsIdentifier', WritingsIdentifier);
          localStorage.setItem('WorksIdentifier', newWorksIdentifier);
          saveChapterNameToDatabase(); //Save the newly created chapter to DB, to avoid any issues that arrise from it not existing
        }, 100); 

          document.getElementById('closecover').click(); // Close


      

    } else {
      alert(data.message); // Handle error messages from the server
    }
  } catch (error) {
    console.error("There was an error adding the book:", error);
  }
}





// Export Book
const handleExport = () => {
  // Determine which radio button is selected
  const selectedSize = document.querySelector('input[name="bookSize"]:checked').value;

    // Save selected size to local storage
    localStorage.setItem('selectedPageSize', selectedSize);
  
  const theworksIdentifier = worksIdentifier;
  const apiEndpoint = 'https://guidedpen.com/API-GuidedPen/exportBook';
  fetch(apiEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ WorksIdentifier: theworksIdentifier, pageSize: selectedSize }),
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    if (data.success) {
      const downloadLinkDiv = document.getElementById('downloadLink');
      downloadLinkDiv.innerHTML = `<a href="${data.fileUrl}" target="_blank">If download doesn't start automatically, click here</a>`;
      window.open(data.fileUrl, '_blank');
    } else {
      throw new Error('Export book request failed: ' + data.message);
    }
  })
  .catch(error => {
    console.error('Error exporting book:', error.message);
    alert('Error initiating book export. See console for details.');
  });
};




const showExportModal = () => {
  // Show the export modal first
  setIsExportModalVisible(true);
  
  // PULLS their last selectedPageSize if it exists in local storage, and pre-selects their radio button
  setTimeout(() => {
    const savedSize = localStorage.getItem('selectedPageSize');
    if (savedSize) {
      // Query all radio buttons and set the correct one based on the saved value
      document.querySelectorAll('input[name="bookSize"]').forEach((radio) => {
        if (radio.value === savedSize) {
          radio.checked = true;
        }
      });
    }
  }, 0); // A timeout of 0 might suffice if your modal is immediately accessible, else adjust the timing
};
const hideExportModal = () => setIsExportModalVisible(false);





const handleTextToSpeech = async () => {
  const { from, to, empty } = editor.state.selection;
  
  console.log('DEBUGGING: from', from);
  console.log('DEBUGGING: to', to);
  console.log('DEBUGGING: empty', empty);

  if (empty) {
    alert("DEBUGGING: Please select text to convert to speech.");
    return; // Exit if no text is selected
  }

  // Calculate the number of characters selected
  const characterCount = to - from;
  console.log('DEBUGGING: Character count', characterCount);

  if (characterCount > 800) {
    alert("Please select a smaller section of text (800 characters maximum).");
    return; // Exit before making the API call
  }

  // Extract text using Tiptap's method if within limits
  const text = editor.state.doc.textBetween(from, to, '\n');
  console.log('DEBUGGING: Selected text is', text);

  const response = await fetch('https://guidedpen.com/API-GuidedPen/textToSpeech', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ text }) // Make sure to send the actual selected text
  });

  if (response.ok) {
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    setAudioUrl(url);
    if (audioRef.current) {
      audioRef.current.src = url;
      audioRef.current.play().catch(e => console.error('Error playing audio:', e));
    }
  } else {
    console.error('Failed to convert text to speech');
  }
};




  return (

<div className={`mostoutside ${isLoggedIn === true ? 'loggedinmostoutside' : 'notloggedinmostoutside'}`}>
  <div id='beatscontainer' className={layoutMode === true ? 'beatscontainer' : 'beatscontainer hidden'}>
    <div className='beatscontainerinner'>
      <h2>Beatstyle for {WorksTitle}:</h2>
      {/* Beat Style Buttons */}
      {beatStyles.map((beatStyle, index) => (
        <button
          key={index}
          id="beatbutton"
          onClick={() => handleBeatStyleClick(beatStyle.BeatStyle)}
          // Replace spaces with nothing to remove them, then add to class name
          className={`${beatlayoutMode === true ? 'beatbutton hidden' : 'beatbutton nonhidden'} ${beatStyle.BeatStyle.replace(/\s+/g, '').replace(/'/g, '')}
          `}
        >
        </button>
      ))}
      <br></br>
      <button onClick={layoutModeToggle} className={layoutMode === true ? 'chevrontoggle' : 'hidden'} id='chevrontoggle'><u>Close</u></button>

      <div className={beatlayoutMode === true ? 'nonhidden' : 'hidden'}>
        <span onClick={() => handleJumpBackToBeat()} id='beatheader'>Beat</span>
        <h2 onClick={() => handleJumpBackToBeat()} id='beatjumpback'><i class="fa-solid fa-chevron-left"></i> Beat Sheets</h2>
        {/* Render beats and matched chapters */}
        {selectedBeatStyle?.IndividualBeats.map((beat, index) => (
          <div key={index} className={`beatbox ${beat.BeatName}`}>
            <h2>{beat.BeatName}</h2>
            <p className='beatdesc'>{beat.Description}</p>
            <ul className="sortable-list beats" id={beat.BeatName}>
              {chapterDetailsArray
                .filter(chapter => beat.BeatName === "Unmatched"
                  ? chapter.displayGroup === "Unmatched"
                  : chapter.displayGroup === beat.BeatName)
                .map((chapter, cIndex) => (
                  <li key={cIndex} draggable className="sortable-item" data-identifier={chapter.WritingsIdentifier} onDrop={(e) => togglehandleDrop(e)}>
                    {chapter.chapterName}
                  </li>
                ))
              }
            </ul>
          </div>
        ))}
      </div>

      <button onClick={saveChapterLayout} className={layoutMode === true ? 'chevrontoggle' : 'hidden'} id='savebeatgroup'>
        {layoutMode ? (
          <i className="fa-solid fa-save"> Save</i>
        ) : (
          <i className="fa-solid fa-chevron-up hidden"></i>
        )}
      </button>

      <button onClick={layoutModeToggle} className={layoutMode === true ? 'chevrontoggle' : 'hidden'} id='chevrontoggle'>
        {layoutMode ? (
          <span>Close</span>
        ) : (
          <i className="fa-solid fa-chevron-up hidden"></i>
        )}
      </button>
    </div>
  </div>

  <div id="guidingarena" className={layoutMode === true ? 'guidingarena guidingarenahidden' : 'guidingarena'}>
    <a href="http://localhost:3000/"><h1 id='h1logo' className={`${fullscreenMode ? 'hidden' : ''}`}>GuidedPen<img className='iconlogo' src={getIconSrc(darkMode)}></img></h1></a>
    
    <select 
      value={selectedModel} 
      onChange={(e) => setSelectedModel(e.target.value)} 
      className='mobileselect'
      style={{ visibility: isLoggedIn ? 'hidden' : 'hidden' }}
    >
      {/* Default option */}
      <option value="1">Model 1</option>
      {/* Render Model 2 */}
      <option value="2">Model 2</option>
    </select>

    <BubbleMenu editor={editor} className={`bubble-menu ${isMenuVisible ? 'visible' : 'visible'}`}>
      <button onClick={aiReword} className="BubbleButton">
        <i class="fa-solid fa-pen-fancy"></i> Reword
      </button>
      <button onClick={aiExpand} className="BubbleButton">
        <i class="fa-solid fa-pen-fancy"></i> Expand
      </button>
      <button onClick={aiBrainStorm} className="BubbleButton">
        <i class="fa-solid fa-pen-fancy"></i> Brainstorm
      </button>
      <button onClick={handleTextToSpeech} className="BubbleButton" title='Lets hear it!'>
        <i class="fa-solid fa-volume-up"></i>
      </button>
    </BubbleMenu>

    <div className='toolbarouter'>
      <h1 id='h1'>
        GuidedPen
        <img className='icon' src={getIconSrc(darkMode)} alt="GuidedPen Icon"/>
      </h1>
    </div>

    <div className={`toolbar ${darkMode} ${fullscreenMode ? 'FullScreenMode' : ''}`}>
      <button 
        id="toolbarmagicwand"
        onClick={aiPlaceText} 
        title="GuidedPen" 
        style={{
          backgroundImage: 'url("https://guidedpen.com/icon.png")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
      </button>

      <button onClick={layoutModeToggle} title="Beat Sheets" className='beatsheetbutton'>
        <span id='beatsheetbuttontext'>{!selectedBeats ? selectedBeats : 'B'}</span>
      </button>

      <button onClick={undoEditor} title="Undo">
        <i className="fas fa-undo"></i>
      </button>
      <button onClick={handleAlignmentClick} className='tbutton'>
        <i className={`fas fa-align-${selectedAlignment}`}></i>
      </button>
      <button title="H1" style={{ display: selectedOption === 0 ? 'inline-block' : 'none' }} onClick={() => handleButtonClick()} className='tbutton'>
        <i class="fa-solid fa-font"></i>
      </button>
      <button title="H2" style={{ display: selectedOption === 1 ? 'inline-block' : 'none' }} onClick={() => handleButtonClick()} className='tbutton'>
        <i class="fa-solid fa-font"></i>
      </button>
      <button title="Paragraph" style={{ display: selectedOption === 2 ? 'inline-block' : 'none' }} onClick={() => handleButtonClick()} className='tbutton'>
        <i class="fa-solid fa-font"></i>
      </button>

      <button onClick={printDocument} title="Print (CTRL+P)" className='tbutton'>
        <i class="fa-solid fa-print"></i>
      </button>

      <button onClick={showExportModal} title="Export Book" className='tbutton'>
        <i class="fa fa-download" aria-hidden="true"></i>
      </button>

      <button onClick={saveChapterNameToDatabase} title="Save (CTRL+S)" className='tbutton' id='saveChapter'>
        <i className="fas fa-save"></i>
      </button>

      {memberLevel !== 'Paid' && (!userIsOwner) && usage > 0 ? (
        <button
          id="UsageCount2"
          title="Unlock GuidedPen"
          onClick={toggleModal}
          className={`${
            fullscreenMode ? '' : 'UsageCountFullScreenMode'} ${
            usage > 300 ? 'usagered' : ''}`}
        >
          {usage}
        </button>
      ) : null}

      <div id='AIButtons' className={`tbutton ${fullscreenMode ? 'hidden' : ''}`}>
        <button id='unset' onClick={unSetfunction}>Reset</button>
      </div>

      <button onClick={() => setSidebarToggle(!SidebarToggle)} id='sidebartogglebutton' className={`${SidebarToggle ? 'showsidebar' : ''} ${fullscreenMode ? 'FullScreenMode' : ''}`}>
        <i class="fa-solid fa-bars"></i>
      </button>
    </div>



  </div>















  <div className='outerguided'>
      <div id='sidebar' className={`${fullscreenMode ? 'FullScreenMode' : ''}`}>
        <div className='notebuttons'>
          <button className='savenotebtn' onClick={saveAINote} title='Save Note'><i class="fa-regular fa-floppy-disk"></i></button>
          <button onClick={handleNewNote} className='savenotebutton2' title='New Note'><i class="fa-solid fa-plus"></i></button>
          <button type='submit' onClick={handleSubmit} className='guidedsubmit' id='guidedsubmit' title="Guide (ENTER)"><i class="fa-solid fa-pen"></i></button>
        </div>

        <div className='ainotes active'>
          {notes.sort((a, b) => new Date(b.LastModified) - new Date(a.LastModified))
            .map((note, index) => (
              <button
                key={index}
                onClick={() => handleNoteButtonClick(note.Note, note.NoteIdentifier, index)}
                className={index === selectedNoteIndex ? 'notes selected-note' : 'notes'}
                title={`Note #${index + 1}`}
              >
                {note.Image && (
                  <img
                    src={note.Image}
                    alt="User"
                    className="note-image"
                    title={note.Name || "Anonymous"}
                    style={{ marginRight: '10px', verticalAlign: 'middle' }}
                  />
                )}
                {note.Note.substring(0, 8)}...
              </button>
            ))
          }
        </div>
      </div>

      <div
  ref={guidingForceInputRef}
  contentEditable={true}
  id="guidingforce"
  className={`guidingforce ${darkMode} ${hideGuidingForce ? '' : ''} ${fullscreenMode ? 'FullScreenMode' : ''}`}
>
  <div contentEditable={false} style={{ marginBottom: '10px' }}>
    {selectedBeatStyle && selectedBeats && (
      <div className="beat-indicator">
        <span className="beat-label">{selectedBeatStyle.BeatStyle}:</span>
        <span className="beat-name">{selectedBeats}</span>
      </div>
    )}
  </div>
  <div dangerouslySetInnerHTML={{ __html: guidingForceContent }} />
</div>

      <button onClick={replaceHighlightedText} className={`${darkMode} ${fullscreenMode ? '' : 'hidden'} ${hideGuidingForce ? 'hidden' : ''}`} id='replaceLeftmagic' title='Replace (CTRL + RIGHT)'
        style={{ zIndex: '5', visibility: isGuidingForceNotEmpty ? 'visible' : 'hidden' }}
      ><i class="fa-solid fa-arrow-up" id="fa-arrow-down"></i></button>

      {showAutoCompleteBox && (
        <div ref={autoCompleteBoxRef} id="autocomplete-box" style={{ position: 'absolute'}}>
          {suggestions.map((suggestion, index) => (
            <div key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion}
            </div>
          ))}
        </div>
      )}

      {memberLevel !== 'Paid' && !userIsOwner ? (
        <button 
          id="UsageCount" 
          title='Unlock GuidedPen' 
          onClick={toggleModal} 
          className={`${fullscreenMode ? 'UsageCountFullScreenMode' : ''} ${usage > 300 ? 'usagered' : ''}`}
        >
          {usage}
        </button>
      ) : null}
    </div>




    



  <div class="middlesection" id="middlesection">
    <div>
      <button onClick={aiPlaceText} title="Pass to guided pen (CTRL + Left)" className={`replaceLeft magicwand ${darkMode} ${fullscreenMode ? 'hidden' : ''}`} id='replaceRight'
        style={{ backgroundImage: 'url("https://guidedpen.com/iconleft.png")'}}
      >
      </button>
      <button onClick={replaceHighlightedText} className={`replaceLeft ${darkMode} ${fullscreenMode ? 'hidden' : ''}`} id='replaceLeft' title='Replace (CTRL + Right)'
        style={{ zIndex: '5', visibility: isGuidingForceNotEmpty ? 'visible' : 'hidden' }}
      ><i class="fa-solid fa-arrow-right" id="fa-arrow-right"></i></button>
      <button id='undo' onClick={undo}
        style={{visibility: isGuidingForceNotEmpty ? 'hidden' : 'visible' }}
      ><i class="fa-solid fa-rotate-left"></i></button>
    </div>
    
    <div className={`buttonlist ${darkMode}`}>
      {buttons.length > 0 ? (
        buttons.map((button) => (
          <button 
            key={button.ButtonID} 
            className={`chapterhighlight ${darkMode}`}
            onClick={() => AICommand(button.ButtonCommand)}>
            {button.ButtonText}
          </button>
        ))
      ) : (
        <div></div>
      )}
    </div>

    <button className={`chapterhighlight ${darkMode}`} onClick={toggleButtonModal}><i class="fa-solid fa-plus"></i></button>
  </div>








  <div className={`editorouter ${darkMode} ${fullscreenMode ? 'editorouterFullScreenMode' : ''}`} id='editorouter'>
    {provider && <EditorContent editor={editor} key={key} />}
  </div>

  <div>
    {showInviteModal && (
      <div className='invitemodal'>
        <div style={{ background: 'rgb(246, 236, 225)', padding: '20px', borderRadius: '10px', width: '80%', maxWidth: '600px', outline: '2px solid rgba(88, 71, 149, 1)', lineHeight: '38px'}}>
          {authorOutreachData ? (
            <>
              <img src={authorOutreachData.Image} className='invitemodalimage' style={{ borderRadius: '20px' }} /><br></br>
              <p>You're invited to write <strong>"{authorOutreachData.Title} <u>2.0</u>"</strong> or another masterpiece with your own GuidedPen!</p>
              <input type="text" value={authorEmail} onChange={(e) => setAuthorEmail(e.target.value)} className='inviteinput' style={{ width: '100%', padding: '10px' }} /><br></br>
              <button id='acceptoutreachbutton' onClick={acceptOutreachInvite} style={{ padding: '10px 20px', cursor: 'pointer' }} className='acceptinvitebutton'>ACCEPT INVITE <i className="fa-solid fa-chevron-right"></i><i className="fa-solid fa-chevron-right"></i></button>
            </>
          ) : (
            <>
              <img src={inviteDetails.ownerImage} className='invitemodalimageinviter' style={{ borderRadius: '20px' }} /><br></br>
              <p>{inviteDetails.writer} has invited you to collaborate on <strong>{inviteDetails.bookName}</strong>:</p>
              <div style={{ marginBottom: '10px' }}>
                <input id="inviteId" type="text" value={inviteDetails.invite} readOnly className='inviteinput inviteidinput' style={{ width: '100%', padding: '10px' }} />
              </div>
              <div style={{ marginBottom: '10px' }}>
                <input id="inviteemail" type="text" value={inviteDetails.email} readOnly className='inviteinput' style={{ width: '100%', padding: '10px' }} />
              </div>
              <button id='acceptinvitebutton' onClick={acceptInvite} style={{ padding: '10px 20px', cursor: 'pointer' }} className='acceptinvitebutton'>ACCEPT INVITE <i className="fa-solid fa-chevron-right"></i><i className="fa-solid fa-chevron-right"></i></button>
            </>
          )}
        </div>
      </div>
    )}
  </div>

  <div id='outsidechapters' className={`outsidechapters ${SidebarToggle ? 'showsidebar' : ''}${fullscreenMode ? 'FullScreenMode' : ''}`}> 
    <div>
      <audio ref={audioRef} hidden>Your browser does not support the audio element.</audio>
    </div>

    <div className='SharedWithToolbar'>
      {isLoggedIn && documentData ? (
        <div>
          <ul>
            <li key={documentData.Owner} style={{ listStyle: 'none', display: 'inline-grid' }} className="shareduserimage" title={`${documentData.OwnerName}`} onClick={() => setSharedModalOpen(true)}>
              {documentData.OwnerImage && documentData.OwnerImage !== "undefined" ?
                <img className='SharedWithImages' src={documentData.OwnerImage} alt={documentData.OwnerName} title={documentData.OwnerName} /> :
                getUserAvatar(documentData.OwnerName)}
            </li>
            {documentData.sharedWith.map(user => (
              <li key={user.email} style={{ listStyle: 'none', display: 'inline-grid' }} className="shareduserimage" title={`${user.name}`} onClick={() => setSharedModalOpen(true)}>
                {user.image && user.image !== "undefined" ? 
                  <img className='SharedWithImages' src={user.image} alt={user.name} title={user.name} /> :
                  getUserAvatar(user.name)}
              </li>
            ))}
            <li className={`sharewithuserimage ${darkMode ? 'dark' : ''}`} onClick={() => setSharedModalOpen(true)}>
              <i className="fa-solid fa-plus sharewithuserimageinner" title="Share book" style={{ cursor: 'pointer' }}></i>
            </li>
          </ul>
        </div>
      ) : null}

{isSharedModalOpen && (
  <div className="shared-modal" onClick={(e) => { if (e.target === e.currentTarget) closeSharedFunction(); }}>
    <div className="shared-modal-content" onClick={e => e.stopPropagation()}>
      <span className="shared-modal-close" onClick={closeSharedFunction}>&times;</span>
      <div>
        {[{
          email: documentData.Owner,
          name: documentData.OwnerName,
          image: documentData.OwnerImage,
          accessType: 'admin'
        }, 
        ...documentData.sharedWith,
        ...emails.map(email => ({
          email,
          accessType: accessLevels[email] || 'edit'
        })).filter(email => !documentData.sharedWith.some(shared => shared.email === email.email))]
        .map((user, index) => {
          return (
            <div key={user.email || `new-${index}`} className="shared-modal-email-tag">
              {user.email}
              {user.email === documentData.Owner && <div className='owner'>Author</div>}
              {user.email !== documentData.Owner && (
                <>
                  <select 
                    value={accessLevels[user.email] || 'edit'}
                    onChange={(e) => {
                      if (documentData.sharedWith.some(shared => shared.email === user.email)) {
                        updateAccessLevel(user.email, e.target.value);
                      } else {
                        setAccessLevels({...accessLevels, [user.email]: e.target.value});
                      }
                    }}
                    className='shareselect'
                    disabled={!userIsOwner && memberLevel !== 'PAID' || documentData.accessType !== 'admin'}
                  >
                    <option value="admin">Author</option>
                    <option value="edit">Editor</option>
                    <option value="view">View</option>
                  </select>
                  <span onClick={() => removeEmail(user.email)} className={!userIsOwner && memberLevel !== 'PAID' || documentData.accessType !== 'admin' ? 'disabled-remove' : 'enabled-remove'}>
                    ×
                  </span>
                </>
              )}
            </div>
          );
        })}
        <input
          id='emailsharewithinput'
          ref={emailInputRef}
          type="text"
          onKeyDown={handleShareKeyDown}
          placeholder="Add email and press Enter..."
          className='shareinput'
          disabled={!userIsOwner && memberLevel !== 'PAID' || documentData.accessType !== 'admin'}
        />
        <button onClick={sendShareToBackend} disabled={isProcessing || (!userIsOwner && memberLevel !== 'PAID' || documentData.accessType !== 'admin')} className='sharebutton'>
          {isProcessing ? 'Processing...' : 'Share >>'}
        </button>
        {!userIsOwner && memberLevel !== 'PAID' || documentData.accessType !== 'admin' && (
          <p className='admininvitenote'>
            Only admin authors can invite people to their books.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>

    <div className="Chapters">
      <div className={`login ${SidebarToggle ? 'showsidebar' : ''}`} id='mobilelogin'>
        {isLoggedIn ? (
          <div>
            <div id="loggedin" className='userimage mobileuserimage' title="Logged In" onClick={toggleModalProfile}>
              <i class="fa-solid fa-book"></i>
            </div>
          </div>
        ) : (
          <div id="login">
            <input type="email" className="loginfield loginemail" value={email} placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
            <input type="password" className="loginfield" value={password} placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
            <button className='loginbutton' onClick={handleLogin}>Login</button>
          </div>
        )}
        <select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)} className='mobileselect mobileselectonmobile' style={{ visibility: isLoggedIn ? 'hidden' : 'hidden' }}>
          <option value="1">Model 1</option>
          <option value="2">Model 2</option>
        </select>
        <button onClick={toggleDarkMode} title="Toggle DarkMode" className={`tbutton topbutton2 mobiledarkmode ${darkMode} ${fullscreenMode ? 'FullScreenMode' : ''}`}>
          <i className="fa-solid fa-moon"></i>
        </button>
      </div>

      <ul className='Chapters-content'>
        <li className='WorkTitleInput' id='WorkTitleInput' onClick={isLoggedIn ? toggleModalProfile : toggleModal}></li>
        {chapters.map((chapter, index) => (
          <li key={index} onClick={() => handleChapterClick(chapter.WritingsIdentifier)} title={chapter.WritingsIdentifier} className={`chapters ${darkMode} ${chapter.WritingsIdentifier === selectedWritingsID ? 'chaptersactive' : ''}`}>
            {chapter.chapterName}
            <button className="DeleteButton" onClick={(event) => {
              event.stopPropagation(); // Prevent triggering the click event on the parent span
              handleDeleteWriting(chapter.WritingsIdentifier);
            }}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </li>
        ))}
        <li>
          <input type="text" id="newchapter" placeholder="New chapter..." title="Press enter" value={newChapterInput} onChange={(e) => setNewChapterInput(e.target.value)} />
        </li>
      </ul>
      <button onClick={() => handleDeleteWriting(writingsID)} hidden="true">Delete this Writing</button>
    </div>
  </div>

  <div className='login' id='mainlogin'>
    {isLoggedIn ? (
      <></>
    ) : (
      <div id="login">
        <input type="email" className="loginfield loginemail" value={email} placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
        <input type="password" className="loginfield" value={password} placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
        <button className='registerbutton' onClick={toggleModal}>Register</button>
        <button className='loginbutton' onClick={handleLogin}>Login</button>
      </div>
    )}
    <div className='topbuttons'>
      <button onClick={() => window.dispatchEvent(new CustomEvent('toggleFullscreenMode'))} title="Full Screen" className={`tbutton topbutton ${darkMode} ${fullscreenMode ? 'FullScreenMode' : ''}`}>
        <i class="fa fa-expand" aria-hidden="true"></i>
      </button>
      <button onClick={toggleDarkMode} title="Toggle DarkMode" className={`tbutton topbutton ${darkMode} ${fullscreenMode ? 'FullScreenMode' : ''}`}>
        <i className="fa-solid fa-moon"></i>
      </button>
    </div>
  </div>

  <div className="highlighted-text">{highlightedText}</div>
  {highlightedText && (
    <div id="highlightexists">Highlighted Text</div>
  )}

  <ButtonOrderModal 
    isButtonOpen={isModalButtonOpen} 
    onButtonClose={toggleButtonModal} 
    buttons={buttons} 
    setButtons={setButtons} 
    moveItem={moveItem} 
    saveButtonOrder={saveButtonOrder} 
    newButtonText={newButtonText} 
    setNewButtonText={setNewButtonText} 
    newButtonCommand={newButtonCommand} 
    setNewButtonCommand={setNewButtonCommand} 
    handleAddButton={handleAddButton} 
  />

  <Modal isOpen={isModalOpen} onClose={toggleModal}>
    {stripePromise && Elements ? (
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    ) : (
      <p>Loading...</p>
    )}
  </Modal>

  <ModalProfile isOpen={isModalProfileOpen} onClose={toggleModalProfile} worksDetails={worksDetails} />

  <div>
    {isExportModalVisible && (
      <>
        <div className='exportModalOuter' onClick={hideExportModal}></div>
        <div className='exportModal' onClick={e => e.stopPropagation()}>
          <h3>Choose book size</h3>
          <div className='sizeselection'>
            <label>
              <input type="radio" name="bookSize" value="5x8" defaultChecked /> 5x8
            </label>
            <label>
              <input type="radio" name="bookSize" value="5.5x8" /> 5.5x8
            </label>
            <label>
              <input type="radio" name="bookSize" value="6x9" /> 6x9
            </label>
          </div><br></br>
          <div id="downloadLink"></div>
          <button className='exportBook' onClick={handleExport}>Export <i class="fa fa-download" aria-hidden="true"></i></button>
          <button onClick={hideExportModal} style={{ marginTop: '10px', display: 'none' }}>Close</button>
          <div className='scribando'>
            Ready to market your incredible creation?<br></br>
            <a href="https://scribando.com" target='_blank'>Get professional help from our friends at <u>Scribando!</u></a>
          </div>
        </div>
      </>
    )}
  </div>
</div>




 );
};

export default EditorComponent;